import { Marker } from '@react-google-maps/api';
import { Polygon } from '@react-google-maps/api';
import logo from './../../../assets/SBLMarker.png';
import { Fragment } from 'react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { geoJsonGuard, getCentroid, IGeoPolygon, IMapStateProps } from './BeachMapState';
import { GERMANY_TIME_ZONE } from 'src/utils/constants';
import { useGetAvailableBeachChairsPublic } from 'src/api/beachChairsAPI';
import { MyMapContext, MyMapContextType } from './mapContext';

const RowMapState = ({ setMapBounds, showMapMarkers }: IMapStateProps) => {
    const { setValue, watch } = useFormContext();
    const { publicReference } = React.useContext(MyMapContext) as MyMapContextType;
    const { setRowsReady } = React.useContext(MyMapContext) as MyMapContextType;
    const { data: beachChairsData } = useGetAvailableBeachChairsPublic(
        watch('startDate') ? new Date(watch('startDate')).toISOString() : '',
        watch('leaveDate') ? new Date(watch('leaveDate')).toISOString() : '',
        watch('sectionId') || '',
        GERMANY_TIME_ZONE,
        publicReference,
    );

    const rowsGeoJsons = React.useMemo(
        () => (beachChairsData ? beachChairsData.filter(row => geoJsonGuard(row.geoJson)) : []),
        [beachChairsData],
    );
    setRowsReady(rowsGeoJsons.length > 0);

    React.useEffect(() => {
        if (rowsGeoJsons.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            if (rowsGeoJsons.length > 1) {
                rowsGeoJsons.forEach(row => {
                    let geoJson = row.geoJson;
                    let centroid = getCentroid(geoJson);
                    bounds.extend({
                        lat: centroid.geometry.coordinates[1],
                        lng: centroid.geometry.coordinates[0],
                    });
                });
            } else {
                rowsGeoJsons.forEach(row => {
                    const path =
                        Object.keys(row.geoJson).length !== 0
                            ? (row.geoJson as IGeoPolygon).coordinates[0].map(coord => {
                                  return { lat: coord[1], lng: coord[0] };
                              })
                            : [{ lat: 0, lng: 0 }];
                    path.forEach(point => {
                        bounds.extend({
                            lat: point.lat,
                            lng: point.lng,
                        });
                    });
                });
            }
            setMapBounds(bounds);
        }
    }, [rowsGeoJsons, setMapBounds]);

    return (
        <>
            {rowsGeoJsons.length > 0 &&
                rowsGeoJsons.map(row => {
                    let geoJson = row.geoJson;
                    let centroid = getCentroid(geoJson);
                    if (geoJson && Object.keys(geoJson).length === 0 && geoJson.constructor === Object) {
                        return <></>;
                    } else {
                        const path = (geoJson as IGeoPolygon).coordinates[0].map(coord => {
                            return { lat: coord[1], lng: coord[0] };
                        });
                        return (
                            <Fragment key={`fragment-${row.id}-${row.name}`}>
                                <Polygon
                                    path={path}
                                    key={`polygon-${row.id}-${row.name}`}
                                    onClick={() => {
                                        setValue('row', row.id);
                                    }}
                                    options={{
                                        strokeColor: '#1A4090',
                                        fillColor: '#ED6B16',
                                        strokeOpacity: 0.78,
                                        strokeWeight: 1,
                                        fillOpacity: 0.5,
                                    }}
                                />
                                {showMapMarkers ? (
                                    <Marker
                                        onClick={() => {
                                            setValue('row', row.id);
                                        }}
                                        key={`marker-${row.id}-${row.name}`}
                                        position={{
                                            lat: centroid.geometry.coordinates[1],
                                            lng: centroid.geometry.coordinates[0],
                                        }}
                                        label={{
                                            text: row.name,
                                            color: 'white',
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                            className: 'MapLabel',
                                        }}
                                        icon={{
                                            url: logo,
                                            scaledSize: new google.maps.Size(50, 50),
                                        }}
                                    />
                                ) : null}
                            </Fragment>
                        );
                    }
                })}
        </>
    );
};

export default RowMapState;
