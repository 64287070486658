import deLocale from 'date-fns/locale/de';
import * as datefns from 'date-fns';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DateRange, MobileDateRangePicker } from '@mui/lab';
import { Box, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { EMapState, MyMapContext, MyMapContextType } from '../views/widget/mapContext';
import { SectionContext, SectionContextType } from '../views/widget/sectionContext';
import { dateToProperDateCartFormat } from 'src/utils/dateFormat';

const rangePickersSpace = {
    width: '100%',
    display: 'grid',
    gap: 1,
    gridTemplateColumns: 'auto auto',
    marginBottom: '8px',
};

const textFieldStyle = {
    width: '100%',
    height: '36px',
    backgroundColor: '#e2e2e2',
};

const requiredStyle = {
    color: 'red',
};

const inputPropsStyle = {
    fontSize: 13,
    fontWeight: 'normal',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: '16px',
} as object;

const boxFlex = {
    display: 'flex',
    fontSize: '14px',
};

const datePickerIconColor = { color: 'rgba(0, 0, 0, 0.54)', cursor: 'pointer' };

interface IFormDatePickerProps {
    disabled?: boolean;
    methods: UseFormReturn<FieldValues>;
    required?: boolean;
    minDate?: any;
    maxDate?: any;
    labelStart?: string;
    labelEnd?: string;
    validationMessage?: string;
}

export const isValidDate = (d: any) => {
    return d instanceof Date && !isNaN(d.getTime());
};

export const yesterday = (): Date => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    return date;
};

const FormDatePicker = ({
    disabled,
    methods,
    minDate = datefns.startOfToday(),
    maxDate = new Date('2100-01-01'),
}: IFormDatePickerProps) => {
    const { setHasSubsections, setState } = React.useContext(MyMapContext) as MyMapContextType;
    const { t } = useTranslation();
    const [open, setOpen] = React.useState<boolean>(false);
    const { sectionData } = React.useContext(SectionContext) as SectionContextType;
    const [value, setValue] = React.useState<DateRange<Date>>([null, null]);
    useEffect(() => {
        if (sectionData && sectionData?.items?.length > 0) {
            const check =
                sectionData?.items[0]?.hasSubsections &&
                methods.watch('startDate') !== undefined &&
                methods.watch('leaveDate') !== undefined;
            setHasSubsections(check);
            if (check) {
                setState(EMapState.ROWS);
            } else {
                setState(EMapState.SECTION);
            }
        } else {
            setState(EMapState.SECTION);
        }

        // eslint-disable-next-line
    }, [sectionData, methods]);

    let minimalDate: Date = minDate;
    let maximalDate: Date = maxDate;

    if (sectionData && sectionData.items && sectionData.items.length > 0 && sectionData.items[0]) {
        if (sectionData.items[0].location.seasonStart && sectionData.items[0].location.seasonEnd) {
            let year = datefns.getYear(minDate);
            const startDayMonth = sectionData.items[0].location.seasonStart.split('-');
            const endDayMonth = sectionData.items[0].location.seasonEnd.split('-');

            if (parseInt(endDayMonth[1]) - 1 < datefns.getMonth(new Date())) {
                year += 1;
            }

            const minimalSeasonDate = new Date(year, +startDayMonth[1] - 1, +startDayMonth[0], 0, 0, 0);

            maximalDate = new Date(year, +endDayMonth[1] - 1, +endDayMonth[0]);

            if (sectionData.items[0].location.leadTimeHours) {
                minimalDate.setTime(
                    minimalDate.getTime() + sectionData.items[0].location.leadTimeHours * 60 * 60 * 1000,
                );
            }
            if (datefns.isBefore(minimalDate, minimalSeasonDate)) {
                minimalDate = minimalSeasonDate;
            }
        }
    }
    const handleOpen = () => {
        if (value[0] === null && value[1] === null) {
            setValue([minimalDate, minimalDate]);
        }
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    React.useEffect(() => {
        let minimalDateEffect: Date = minDate;
        if (sectionData && sectionData.items && sectionData.items.length > 0 && sectionData.items[0]) {
            if (sectionData.items[0].location.seasonStart && sectionData.items[0].location.seasonEnd) {
                let year = datefns.getYear(minDate);
                const startDayMonth = sectionData.items[0].location.seasonStart.split('-');
                const endDayMonth = sectionData.items[0].location.seasonEnd.split('-');
                if (parseInt(endDayMonth[1]) - 1 < datefns.getMonth(new Date())) {
                    year += 1;
                }
                const minimalSeasonDate = new Date(year, +startDayMonth[1] - 1, +startDayMonth[0], 0, 0, 0);
                if (sectionData.items[0].location.leadTimeHours) {
                    minimalDateEffect.setTime(
                        minimalDateEffect.getTime() + sectionData.items[0].location.leadTimeHours * 60 * 60 * 1000,
                    );
                }
                if (datefns.isBefore(minimalDateEffect, minimalSeasonDate)) {
                    minimalDateEffect = minimalSeasonDate;
                }
            }
            setValue([minimalDateEffect, minimalDateEffect]);
            methods.setValue('startDate', minimalDateEffect.toISOString());
            methods.setValue('leaveDate', minimalDateEffect.toISOString());
        }
        // eslint-disable-next-line
    }, [sectionData]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
            <MobileDateRangePicker
                disabled={disabled}
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                value={value}
                onChange={newValue => {
                    if (newValue && newValue[0] && isValidDate(newValue[0])) {
                        methods.setValue('startDate', newValue[0].toISOString());
                        methods.setValue('leaveDate', newValue[0].toISOString());
                        setValue([newValue[0], newValue[0]]);
                    }

                    if (newValue && newValue[1] && isValidDate(newValue[1])) {
                        methods.setValue('leaveDate', newValue[1].toISOString());
                        setValue(newValue);
                    }

                    if (
                        newValue &&
                        newValue[0] &&
                        newValue[1] &&
                        isValidDate(newValue[0]) &&
                        isValidDate(newValue[1])
                    ) {
                        methods.setValue('startDate', newValue[0].toISOString());
                        methods.setValue('leaveDate', newValue[1].toISOString());
                        setValue(newValue);
                    }
                }}
                InputProps={{ readOnly: true }}
                startText={t('common:pickup')}
                endText={t('common:dropoff')}
                toolbarTitle={t('common:timerange')}
                cancelText={t('common:cancellation')}
                inputFormat="dd.MM.yyyy"
                mask="__.__.____"
                minDate={minimalDate}
                maxDate={maximalDate}
                renderInput={() => <></>}
            />
            {sectionData ? (
                <Box sx={{ ...rangePickersSpace }}>
                    <Box>
                        <Box sx={{ ...boxFlex }}>
                            {t('common:pickup')}
                            <Box sx={{ ...requiredStyle }}>*</Box>
                        </Box>
                        <TextField
                            variant="standard"
                            sx={{
                                ...textFieldStyle,
                            }}
                            required
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true,
                                style: inputPropsStyle,
                                endAdornment: (
                                    <DateRangeIcon
                                        sx={{ ...datePickerIconColor }}
                                        onClick={disabled ? () => {} : handleOpen}
                                    />
                                ),
                            }}
                            InputLabelProps={{ shrink: false }}
                            inputProps={{
                                placeholder: 'TT.MM.JJJJ',
                            }}
                            label=""
                            onClick={() => {
                                handleOpen();
                            }}
                            value={dateToProperDateCartFormat(value[0]?.toISOString() || new Date().toISOString())}
                        />
                    </Box>
                    <Box>
                        <Box sx={{ ...boxFlex }}>
                            {t('common:dropoff')}
                            <Box sx={{ ...requiredStyle }}>*</Box>
                        </Box>
                        <TextField
                            variant="standard"
                            sx={{
                                ...textFieldStyle,
                            }}
                            required
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true,
                                style: inputPropsStyle,
                                endAdornment: (
                                    <DateRangeIcon
                                        sx={{ ...datePickerIconColor }}
                                        onClick={disabled ? () => {} : handleOpen}
                                    />
                                ),
                            }}
                            InputLabelProps={{ shrink: false }}
                            inputProps={{
                                placeholder: 'TT.MM.JJJJ',
                            }}
                            label=""
                            onClick={() => {
                                handleOpen();
                            }}
                            value={dateToProperDateCartFormat(value[1]?.toISOString() || new Date().toISOString())}
                        />
                    </Box>
                </Box>
            ) : null}
        </LocalizationProvider>
    );
};

export default FormDatePicker;
