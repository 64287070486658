import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import qs from 'qs';
import React, { useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import {
    IVendor,
    putBookingConfirmPublic,
    updateBeachChairBooking,
    updateBeachChairBookingPublic,
    useCreateCustomerPublic,
} from 'src/api/beachChairsAPI';

import FormDropdown from 'src/components/form/FormDropdown';
import FormInput from 'src/components/form/FormInput';
import { emailPattern } from 'src/components/form/useFormUtil';
import PayPalSection from 'src/components/paypal/PayPalSection';
import { useAlertDialog } from 'src/hook';
import { useDialog } from 'src/hook/DialogContext';
import { EBookingPaymentMethod } from 'src/models/bookings/EBookingPaymentMethod';
import messageSet from 'src/utils/message';
import { useWidgetState } from './WidgetContext';
import LoadingButton from 'src/components/common/button/LoadingButton';
import FormRadioGroup from 'src/components/form/FormRadioGroup';
import { useDebouncedCallback } from 'use-debounce/lib';
import { GERMANY_TIME_ZONE } from 'src/utils/constants';
import * as ibantools from 'ibantools';
import { SectionContext, SectionContextType } from './sectionContext';
import { apiConfig } from 'src/config/config';
import * as localizedCountries from 'localized-countries/data/de.json';
import client from 'src/api/api';

const countriesArray = ['DE', 'AT', 'CH', 'DK', 'PL', 'NL', 'IT', 'ES', 'SE', 'NO', 'FR', 'CZ', 'HR', 'GB'];

const CustomerDataPanelBlock = styled.div<{ isShown: boolean }>`
    display: ${props => (props.isShown ? 'flex' : 'none')};
    width: 100%;
    flex-direction: column;
    margin: auto;
    margin-top: 80px;
    max-width: 550px;

    .main-text {
        font-size: 36px;
    }

    .sub-text {
        font-size: 12px;
    }

    .ticketSelect-button {
        background-color: #e8ebed;
        height: 45px;
        min-width: 127px;
        display: flex;
        flex-direction: column;
        padding: 8px;
        opacity: 0.5;

        & .price {
            font-size: 16px;
            width: 100%;
            text-align: right;
        }
        & .ticket-name {
            font-size: 10px;
            width: 100%;
            text-align: left;
        }
        & .ticket-time {
            color: grey;
            margin-left: 4px;
            font-size: 8px;
        }
        &.selected-ticket {
            opacity: 1;
        }
    }

    .row-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 14px;

        & > * {
            flex: 1;
        }

        & > * + * {
            margin-left: 10px;
        }
    }
    .title-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 34px;

        &.payment-title {
            margin-top: 45px;
        }
    }
    .next-btn-container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }
    #countrySelect {
        padding-left: 10px;
    }
`;

interface ICustomerDataPanelProps {
    isShown: boolean;
    shownTrigger: () => void;
}

const salutationArray = ['MR', 'MS', 'MX'];

const CustomerDataPanel: React.FC<ICustomerDataPanelProps> = ({ isShown, shownTrigger }) => {
    const myObj = (localizedCountries as any).default;

    const { t } = useTranslation(['common', 'widget']);
    const location = useLocation();
    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true,
    });
    const { sectionData } = React.useContext(SectionContext) as SectionContextType;
    const publicReference = typeof query?.publicReference === 'string' ? query.publicReference : '';
    const { bookedId, price, bookedRef } = useWidgetState();
    const {
        refetch: createCustomer,
        error: createCustomerError,
        isLoading: createCustomerLoading,
    } = useCreateCustomerPublic(bookedRef, publicReference);

    const [data, setData] = React.useState<IVendor>();

    const getVendorByPublicreference = React.useCallback(async () => {
        const response = await client.get(`/features/public/vendor?publicReference=${publicReference}`);
        setData(response.data);
    }, [publicReference]);

    React.useEffect(() => {
        if (publicReference) {
            getVendorByPublicreference();
        }
    }, [publicReference, getVendorByPublicreference]);

    const [termsPath, setTermsPath] = useState('');

    let paymentMethodsOptions =
        data?.paymentMethods?.map(method => {
            return {
                id: Object.keys(EBookingPaymentMethod).indexOf(method),
                value: method,
                label: t(`widget:paymentMethod.${method}`),
            };
        }) || [];
    const history = useHistory();
    const { showDialog } = useDialog();
    const { showAlertDialog } = useAlertDialog();

    const validationSchema = useMemo(
        () =>
            yup.object().shape({
                paymentMethod: yup.string().required(),
                terms: yup.boolean().required(),
                firstName: yup.string().required(t("firstName") + t("isMandatory")),
                lastName: yup.string().required(t("lastName") + t("isMandatory")),
                salutation: yup.string(),
                address: yup.object({
                  zip: yup.string().required(t("zip") + t("isMandatory")),
                  city: yup.string().required(t("city") + t("isMandatory")),
                  street: yup.string().required(t("street") + t("isMandatory")),
                  country: yup.string(),
                }),
                telephone: yup.string(),
                email: yup
                  .string()
                  .email(t("email") + t("isMandatory"))
                  .required(t("email") + t("isMandatory")),
            }),
        [t],
    );

    const resolver = yupResolver(validationSchema);
    const methods = useForm({ resolver });
    const watchPaymentMethod = methods.watch('paymentMethod');
    const watchEmail = methods.watch('email');
    const watchTelephone = methods.watch('telephone');
    const watchTerms = methods.watch('terms');
    const watchFirstName = methods.watch('firstName');
    const watchLastName = methods.watch('lastName');
    const watchZip = methods.watch('address.zip');
    const watchCity = methods.watch('address.city');
    const watchStreet = methods.watch('address.street');
    const debounced = useDebouncedCallback(
        // function
        () => {
            createCustomer({
                data: {
                    email: watchEmail,
                    firstName: methods.getValues('firstName') || null,
                    lastName: methods.getValues('lastName') || null,
                    salutation: methods.getValues('salutation') || null,
                    address: {
                        zip: methods.getValues('address.zip') || '',
                        city: methods.getValues('address.city') || '',
                        street: methods.getValues('address.street') || '',
                        country: methods.getValues('address.country') || '',
                    },
                    telephone: methods.getValues('telephone') || null,
                    additionalData: {
                        marketing: methods.getValues('additionalData.marketing') || false,
                    },
                },
            });
        },
        // delay in ms
        1000,
    );

    React.useEffect(() => {
        const locationObj = sectionData?.items[0]?.location;
        setTermsPath(`${apiConfig.apiPrefix}/vendor/vendors/terms/${locationObj?.vendorId}/pdf`);
    }, [sectionData]);

    React.useEffect(() => {
        if (watchEmail && emailPattern.test(watchEmail)) {
            debounced();
        }
        // eslint-disable-next-line
    }, [watchEmail, watchTelephone]);

    React.useEffect(() => {
        if (watchPaymentMethod) {
            updateBeachChairBookingPublic(watchEmail, bookedRef, {
                paymentMethod: watchPaymentMethod,
            });
        }
        // eslint-disable-next-line
    }, [watchPaymentMethod]);

    React.useEffect(() => {
        if (
            ibantools.isValidBIC(methods.watch('bic') || '') &&
            ibantools.isValidIBAN(methods.getValues('iban') || '')
        ) {
            const bankDetails = {
                bic: methods.getValues('bic') === '' ? undefined : methods.getValues('bic'),
                iban: methods.getValues('iban'),
                accountHolder:
                    methods.getValues('accountHolder') === '' ? undefined : methods.getValues('accountHolder'),
                bankName: methods.getValues('bankName') === '' ? undefined : methods.getValues('bankName'),
            };

            updateBeachChairBookingPublic(methods.getValues('email') ?? '', bookedRef, {
                paymentMethod: watchPaymentMethod,
                bankDetails: bankDetails,
            });
        }
        // eslint-disable-next-line
    }, [methods.watch('bic'), methods.watch('iban'), methods.watch('accountHolder'), methods.watch('bankName')]);

    const onSubmitHandler = async (formData: any) => {
        const addressKeyArr = Object.keys(formData.address);
        addressKeyArr.forEach(key => {
            if (formData.address[key] === undefined || formData.address[key] === '') {
                delete formData.address[key];
            }
        });

        if (Object.keys(formData.address).length === 0) {
            delete formData.address;
        }

        const copiedFormDataWithoutPaymentMethod = { ...formData };
        delete copiedFormDataWithoutPaymentMethod.paymentMethod;
        delete copiedFormDataWithoutPaymentMethod.terms;

        try {
            if ([EBookingPaymentMethod.BANK_TRANSFER, EBookingPaymentMethod.STRIPE_BANK_TRANSFER].includes(watchPaymentMethod)) {
                // CASE for BankTransfer
                await createCustomer({
                    data: copiedFormDataWithoutPaymentMethod,
                });

                /*const { data: bookingData } = */ await updateBeachChairBookingPublic(
                    methods.getValues('email') ?? '',
                    bookedRef,
                    {
                        paymentMethod: formData.paymentMethod,
                    },
                );
                await putBookingConfirmPublic(methods.getValues('email') ?? '', bookedRef, GERMANY_TIME_ZONE);
                console.log({bookedRef});
                
                history.push(`/beach-chairs/result/query?email=${methods.getValues('email')}&bookingRef=${bookedRef}`);
            } else if (
                [
                    EBookingPaymentMethod.CC,
                    EBookingPaymentMethod.OTHER_STRIPE,
                    EBookingPaymentMethod.STRIPE_DIRECT_DEBIT,
                    EBookingPaymentMethod.STRIPE_SOFORT,
                    EBookingPaymentMethod.STRIPE_CREDIT_CARD,
                    EBookingPaymentMethod.STRIPE_PAYPAL,
                ].includes(watchPaymentMethod)
            ) {
                // CASE for CreditCard-Stripe
                await createCustomer({ data: copiedFormDataWithoutPaymentMethod });
                await updateBeachChairBookingPublic(methods.getValues('email') ?? '', bookedRef, {
                    paymentMethod: formData.paymentMethod,
                });
            } else if (watchPaymentMethod === EBookingPaymentMethod.CUSTOM_SEPA) {
                const bankDetails = {
                    bic: methods.getValues('bic') === '' ? undefined : methods.getValues('bic'),
                    iban: methods.getValues('iban'),
                    accountHolder:
                        methods.getValues('accountHolder') === '' ? undefined : methods.getValues('accountHolder'),
                    bankName: methods.getValues('bankName') === '' ? undefined : methods.getValues('bankName'),
                };

                await updateBeachChairBookingPublic(methods.getValues('email') ?? '', bookedRef, {
                    paymentMethod: formData.paymentMethod,
                    bankDetails: bankDetails,
                });

                await putBookingConfirmPublic(methods.getValues('email') ?? '', bookedRef, GERMANY_TIME_ZONE);

                history.push(`/beach-chairs/result/query?email=${methods.getValues('email')}&bookingRef=${bookedRef}`);
            } else {
                // TODO: this case is for PAYPAL
                await createCustomer({ data: copiedFormDataWithoutPaymentMethod });

                await updateBeachChairBooking(bookedId, { paymentMethod: formData.paymentMethod });
                showDialog({
                    title: <Box color="primary.main">{t('widget:payment').toUpperCase()}</Box>,
                    body: <PayPalSection price={price} />,
                    maxWidth: 'lg',
                    secondaryButton: {
                        text: t('cancel').toUpperCase(),
                    },
                });
            }
        } catch (error) {
            showAlertDialog({
                alertIconType: 'ERROR',
                title: 'ERROR',
                body:
                    // error.response?.data?.message ||
                    createCustomerError?.response?.data?.message || messageSet.unhandledMessage,
                primaryButton: { text: 'OK' },
            });
        }
    };

    return (
        <CustomerDataPanelBlock isShown={isShown}>
            <FormProvider {...methods}>
                <form
                    autoComplete="off"
                    target="_parent"
                    action={
                        [
                            EBookingPaymentMethod.CC,
                            EBookingPaymentMethod.OTHER_STRIPE,
                            EBookingPaymentMethod.STRIPE_DIRECT_DEBIT,
                            EBookingPaymentMethod.STRIPE_SOFORT,
                            EBookingPaymentMethod.STRIPE_CREDIT_CARD,
                            EBookingPaymentMethod.STRIPE_PAYPAL,
                        ].includes(watchPaymentMethod)
                            ? `${apiConfig.apiPrefix}/payments/stripe/checkout-session?email=${methods.getValues(
                                  'email',
                              )}&bookingRef=${encodeURIComponent(bookedRef)}`
                            : ''
                    }
                    onSubmit={
                        [
                            EBookingPaymentMethod.CC,
                            EBookingPaymentMethod.OTHER_STRIPE,
                            EBookingPaymentMethod.STRIPE_DIRECT_DEBIT,
                            EBookingPaymentMethod.STRIPE_SOFORT,
                            EBookingPaymentMethod.STRIPE_CREDIT_CARD,
                            EBookingPaymentMethod.STRIPE_PAYPAL,
                        ].includes(watchPaymentMethod)
                            ? () => {}
                            : methods.handleSubmit(onSubmitHandler)
                    }
                    method="POST"
                >
                    <div className="title-container">
                        <Typography className="main-text">{t('widget:customerData')}</Typography>
                    </div>
                    <div className="row-container">
                        <FormDropdown
                            name="salutation"
                            defaultValue=''
                            label={t('widget:salutation')}
                            options={salutationArray.map(item => ({
                                name: t('widget:salutations.' + item),
                                value: item,
                            }))}
                        />
                    </div>
                    <div className="row-container">
                        <FormInput 
                            name="firstName" 
                            required
                            label={t('widget:firstName')} />
                        <FormInput 
                            name="lastName" 
                            required
                            label={t('widget:lastName')} />
                    </div>
                    <div className="row-container">
                        <FormInput 
                            name="address.street" 
                            required
                            label={t('widget:street')} />
                        <FormDropdown
                            name="address.country"
                            defaultValue={(myObj as any)['DE']}
                            label={t('widget:country')}
                            options={countriesArray.map((countrySelect, ind) => ({
                                name: (myObj as any)[countrySelect],
                                value: (myObj as any)[countrySelect],
                            }))}
                        />
                    </div>
                    <div className="row-container">
                        <FormInput 
                            name="address.zip" 
                            required 
                            label={t('widget:zip')} />
                        <FormInput 
                            name="address.city" 
                            required 
                            label={t('widget:city')} />
                    </div>
                    <div className="row-container">
                        <FormInput name="telephone" label={t('widget:telephoneNumber')} />
                        <FormInput 
                            name="email" 
                            required 
                            label={t('widget:email')} />
                    </div>

                    <div className="title-container payment-title">
                        <Typography className="main-text">{t('widget:payment')}</Typography>
                    </div>
                    <div className="row-container">
                        <FormRadioGroup
                            disabled={!emailPattern.test(watchEmail)}
                            aria-label="paymentMethod"
                            name="paymentMethod"
                            options={paymentMethodsOptions}
                            required
                            rules={{ required: t('common:requiredNotification') as string }}
                        />
                    </div>
                    {watchPaymentMethod === EBookingPaymentMethod.CUSTOM_SEPA && (
                        <>
                            <div className="row-container">
                                <FormInput
                                    disabled={!emailPattern.test(watchEmail)}
                                    name="iban"
                                    required
                                    label={t('widget:sepa.iban')}
                                />
                                <FormInput
                                    disabled={!emailPattern.test(watchEmail)}
                                    name="bic"
                                    required
                                    label={t('widget:sepa.bic')}
                                />
                            </div>
                            <div className="row-container">
                                <FormInput
                                    disabled={!emailPattern.test(watchEmail)}
                                    name="accountHolder"
                                    label={t('widget:sepa.accountHolder')}
                                />
                                <FormInput
                                    disabled={!emailPattern.test(watchEmail)}
                                    name="bankName"
                                    label={t('widget:sepa.bankName')}
                                />
                            </div>
                        </>
                    )}

                    <div className="row-container">
                        <Controller
                            defaultValue={false}
                            control={methods.control}
                            name="terms"
                            render={({ field }) => (
                                <FormControlLabel
                                    value="terms"
                                    control={
                                        <Checkbox
                                            id="terms"
                                            checked={field.value}
                                            name="terms"
                                            required
                                            color="primary"
                                            onChange={e => {
                                                field.onChange(e.target.checked);
                                            }}
                                        />
                                    }
                                    label={
                                        <>
                                          {t("widget:termsLink.textBegin")}
                                          <a
                                            href="https://app.strandbutler.de/content/terms"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {t("widget:terms.titleTerms")}
                                          </a>
                                          {t("widget:termsLink.textAfterTerms")}
                                          <a
                                            href="https://app.strandbutler.de/content/dataprivacy"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {t("widget:termsLink.titlePrivacy")}
                                          </a>
                                          {t("widget:termsLink.textMiddle")}
                                          <a
                                            href={`${termsPath}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {t("widget:termsLink.titleLink")}
                                          </a>
                                          {t("widget:termsLink.textEnd")}
                                        </>
                                    }
                                    // {
                                    //     <>
                                    //         {t('widget:terms.textBegin')}
                                    //         <a href={`${termsPath}`} target="_blank" rel="noopener noreferrer">
                                    //             {t('widget:terms.titleTerms')}
                                    //         </a>
                                    //         {t('widget:terms.textMiddle')}
                                    //         <a
                                    //             href="https://app.strandbutler.de/content/dataprivacy"
                                    //             target="_blank"
                                    //             rel="noopener noreferrer"
                                    //         >
                                    //             {t('widget:terms.titlePrivacy')}
                                    //         </a>
                                    //         {t('widget:terms.textEnd')}
                                    //     </>
                                    // }
                                    labelPlacement="end"
                                />
                            )}
                        />
                    </div>
                    <div className="row-container">
                        <Controller
                            defaultValue={false}
                            control={methods.control}
                            name="additionalData.marketing"
                            render={({ field }) => (
                                <FormControlLabel
                                    value="additionalData.marketing"
                                    control={
                                        <Checkbox
                                            id="additionalData.marketing"
                                            checked={field.value}
                                            name="additionalData.marketing"
                                            color="primary"
                                            onChange={e => {
                                                field.onChange(e.target.checked);
                                            }}
                                        />
                                    }
                                    label={t('common:marketing')}
                                    labelPlacement="end"
                                />
                            )}
                        />
                    </div>
                    <div className="next-btn-container">
                        <Button
                            variant="outlined"
                            onClick={e => {
                                shownTrigger();
                            }}
                        >
                            {t('widget:back')}
                        </Button>
                        <LoadingButton
                            disabled={
                                watchPaymentMethod === EBookingPaymentMethod.CUSTOM_SEPA
                                    ? !(
                                          watchTerms &&
                                          watchFirstName &&
                                          watchLastName &&
                                          watchZip &&
                                          watchCity &&
                                          watchStreet &&
                                          emailPattern.test(watchEmail) &&
                                          watchPaymentMethod &&
                                          ibantools.isValidIBAN(methods.getValues('iban') || '')
                                      )
                                    : !(
                                        watchTerms && 
                                        watchFirstName &&
                                        watchLastName &&
                                        watchZip &&
                                        watchCity &&
                                        watchStreet &&
                                        emailPattern.test(watchEmail) && 
                                        watchPaymentMethod
                                    )
                            }
                            color="primary"
                            variant="contained"
                            type="submit"
                            loading={createCustomerLoading}
                        >
                            {t('widget:next')}
                        </LoadingButton>
                    </div>
                </form>
            </FormProvider>
        </CustomerDataPanelBlock>
    );
};

export default CustomerDataPanel;
