import { Box, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import * as React from 'react';
import { Controller, DeepMap, FieldError, FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { SelectionProps } from './formDataType';

const FormRadioButtonGroupsBlock = styled.div`
    .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .label {
        margin-bottom: 3;
        font-size: 14px;
        font-weight: 400;
        color: black;
        & .required-star {
            color: #ff0000;
        }
    }

    .helper {
        font-size: 14px;
        font-weight: 400;
        color: #ff0000;
    }
`;

interface RadioGroupCompProps {
    disabled: boolean;
    options: SelectionProps[];
    label?: string;
    required?: boolean;
    error?: FieldError;
    dirtyFields?: DeepMap<FieldValues, true>;
    validationMessage?: string;
    name: string;
}

export function RadioGroupComponent({
    disabled,
    options,
    label,
    required,
    error,
    dirtyFields,
    validationMessage,
    name,
    ...props
}: RadioGroupCompProps) {
    return (
        <FormRadioButtonGroupsBlock>
            <div className="header">
                {label ? (
                    <label className="label">
                        {label}
                        {required && <span className="required-star">*</span>}
                    </label>
                ) : (
                    <div></div>
                )}
                <span className="helper">
                    {error ? error?.message || 'Invalid' : dirtyFields?.[name] && validationMessage}
                </span>
            </div>
            <RadioGroup style={{ width: '100%' }} row {...props}>
                {options.map(option => (
                    <Box key={option.id} textAlign={'center'} mr={3}>
                        <FormControlLabel
                            control={<Radio color="primary" size="small" />}
                            disabled={disabled}
                            label={option.label || ''}
                            labelPlacement={'end'}
                            value={option.value}
                        />
                    </Box>
                ))}
            </RadioGroup>
        </FormRadioButtonGroupsBlock>
    );
}

interface IFormRadioGroupProps {
    defaultValue?: string;
    disabled?: boolean;
    name: string;
    options?: SelectionProps[];
    rules?: RegisterOptions;
    label?: string;
    validationMessage?: string;
    required?: boolean;
}

const FormRadioGroup: React.FC<IFormRadioGroupProps> = ({
    defaultValue = '',
    disabled = false,
    name,
    options = [],
    label,
    rules,
    required,
    validationMessage,
}) => {
    const { control } = useFormContext();

    return (
        <Controller
            aria-label={name}
            render={({ field: { ref, ...rest }, fieldState: { error }, formState: { dirtyFields } }) => (
                <RadioGroupComponent
                    disabled={disabled}
                    options={options}
                    label={label}
                    required={required}
                    error={error}
                    dirtyFields={dirtyFields}
                    validationMessage={validationMessage}
                    {...rest}
                />
            )}
            control={control}
            defaultValue={defaultValue}
            name={name}
            rules={rules}
        />
    );
};

export default FormRadioGroup;
