import React, { useState } from 'react';
import CustomerDataPanel from 'src/components/views/widget/CustomerDataPanel';
import SelectTicketPanel from 'src/components/views/widget/SelectTicketPanel';
import { SectionContextProvider } from 'src/components/views/widget/sectionContext'

const BookBeachChairPage: React.FC = () => {
    const [showScreens, setShowScreens] = useState<boolean[]>([true, false]);

    return (
        <SectionContextProvider>
            <SelectTicketPanel isShown={showScreens[0]} shownTrigger={() => setShowScreens([false, true])} key="SelectTicketPanel" />
            <CustomerDataPanel isShown={showScreens[1]} shownTrigger={() => setShowScreens([true, false])} key="CustomerDataPanel" />
        </SectionContextProvider>
    );
};

export default BookBeachChairPage;
