import { useMemo } from 'react';
import { makeStyles, Button, CircularProgress, ButtonProps } from '@material-ui/core';

const useStyles = makeStyles({
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

interface Props extends ButtonProps {
    loading: boolean;
}

export default function LoadingButton({ disabled, loading, ...props }: Props) {
    const classes = useStyles();

    const progressColor = useMemo(
        () => (props.color === 'primary' || props.color === 'secondary' ? props.color : 'inherit'),
        [props.color],
    );

    return (
        <Button disabled={disabled || loading} {...props}>
            {props.children}
            {loading && <CircularProgress className={classes.buttonProgress} color={progressColor} size={24} />}
        </Button>
    );
}
