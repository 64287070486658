import { useFormContext, RegisterOptions, Controller } from 'react-hook-form';
import styled from 'styled-components';
import Dropdown, { DropdownOptionProps } from 'src/components/dropdown/Dropdown';

const FormDropdownBlock = styled.div`
    .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .label {
        margin-bottom: 3;
        font-size: 14px;
        font-weight: 400;
        color: black;
        & .required-star {
            color: #ff0000;
        }
    }

    .helper {
        max-width: 271px;
        font-size: 14px;
        font-weight: 400;
        color: #ff0000;
    }
`;

interface IProps {
    defaultValue?: string;
    name: string;
    disabled?: boolean;
    rules?: RegisterOptions;
    width?: number | string;
    height?: number;
    placeholder?: string;
    options: DropdownOptionProps[];
    className?: string;
    label?: string;
    required?: boolean;
    validationMessage?: string;
    onClick?: () => void;
}

const FormDropdown: React.FC<IProps> = ({
    defaultValue,
    name,
    required,
    label,
    validationMessage,
    rules = {},
    onClick,
    disabled,
    options,
    placeholder,
    ...props
}) => {
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            defaultValue={defaultValue}
            name={name}
            rules={rules}
            render={({ field, fieldState: { error }, formState: { dirtyFields } }) => (
                <FormDropdownBlock>
                    <div className="header">
                        {label && (
                            <label className="label">
                                {label}
                                {required && <span className="required-star">*</span>}
                            </label>
                        )}
                        <span className="helper">
                            {error ? error?.message || 'Invalid' : dirtyFields?.[name] && validationMessage}
                        </span>
                    </div>
                    <Dropdown
                        inputRef={field.ref}
                        error={!!error}
                        placeholder={placeholder}
                        options={options}
                        disabled={disabled && !!!options.length}
                        {...field}
                        {...props}
                        onClick={onClick}
                    />
                </FormDropdownBlock>
            )}
        />
    );
};

export default FormDropdown;
