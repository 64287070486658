import { ThemeProvider } from '@material-ui/core';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { ModalContextProvider } from './components/common/modal/ModalContext';
import { SnackBarContextProvider } from './components/common/snackbar/SnackBarContext';
import './index.css';
import customTheme from './utils/customTheme/customTheme';
import './utils/i18n/i18n';

//import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
    <ThemeProvider theme={customTheme}>
        <HelmetProvider>
            <BrowserRouter>
                <ModalContextProvider>
                    <SnackBarContextProvider>
                        <App />
                    </SnackBarContextProvider>
                </ModalContextProvider>
            </BrowserRouter>
        </HelmetProvider>
    </ThemeProvider>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
