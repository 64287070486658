import * as React from 'react';
import { createContext } from 'react';
import useSections from 'src/hook/useSections';
import { ISections } from 'src/models/vendor/ISections';

export type SectionContextType = {
    sectionId: number;
    setSectionId: React.Dispatch<React.SetStateAction<number>>;
    sectionData?: ISections;
};

export const SectionContext = createContext<SectionContextType | undefined>(undefined);

type SectionProviderProps = { children: React.ReactNode };

function SectionContextProvider({ children }: SectionProviderProps) {
    const [sectionId, setSectionId] = React.useState(0);
    const { sectionData } = useSections(sectionId);
    const value = { sectionId, setSectionId, sectionData };
    return <SectionContext.Provider value={value}>{children}</SectionContext.Provider>;
}

export { SectionContextProvider };
