import { MenuItem, Select, SelectProps } from '@material-ui/core';
import clsx from 'clsx';
import styled from 'styled-components';

interface StyleProps {
    width?: number | string;
    height?: number;
}

const DropdownBlock = styled(Select)<StyleProps>`
    &.dropdown-main {
        width: ${props => props?.width || '100%'};
        height: ${props => props?.height || '32px'};
        font-size: 0.875rem;
        font-weight: 300;
        color: #000000;
        background-color: #e2e2e2;

        & .MuiSelect-selectMenu {
            min-height: auto;
        }
        & .MuiSelect-select:focus {
            background-color: initial;
        }
        &.disabled {
            pointer-events: none;
        }
        &.disabled .MuiSvgIcon-root {
            color: #abadb1;
            pointer-events: none;
        }
        padding: 0 16px;
    }

    .MuiSelect-selectMenu {
        font-weight: 400;
    }

    .icon {
        color: #344561;
        right: 10px;
    }

    .paper {
        max-height: 144px;
        background: #0a111f;
        border-radius: 8px;
        margin-top: 8px;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .menuItem {
        opacity: 0.8;
        font-size: 0.75rem;
        font-weight: 300;
        color: #abadb1;
        justify-content: center;
    }

    .placeholder {
        opacity: 0.5;
    }
`;

export interface DropdownOptionType {
    name: string;
    value: string | number;
}

export type DropdownOptionProps = DropdownOptionType | string;

interface DropdownProps extends SelectProps {
    width?: number | string;
    height?: number;
    placeholder?: string;
    options: DropdownOptionProps[];
    disabled?: boolean;
    value: string;
}

export default function Dropdown({
    className,
    width,
    height = 40,
    placeholder,
    options,
    error,
    disabled,
    value = '',
    ...props
}: DropdownProps) {
    return (
        <DropdownBlock
            className={clsx(
                className,
                'dropdown-main',
                error && 'error',
                disabled && 'disabled',
                options.length === 1 && 'disabled'
            )}
            classes={{
                icon: 'icon',
            }}
            MenuProps={{
                classes: {
                    paper: 'paper',
                },
                getContentAnchorEl: null,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            }}
            disableUnderline
            data-testid="dropdown-element"
            disabled={disabled}
            placeholder={placeholder}
            value={value}
            {...props}
        >
            {options.map((option: DropdownOptionProps, index) => (
                <MenuItem
                    key={typeof option === 'string' ? option : index}
                    className="menuItem"
                    value={typeof option === 'string' ? option : option.value}
                >
                    {typeof option === 'string' ? option : option.name}
                </MenuItem>
            ))}
        </DropdownBlock>
    );
}
