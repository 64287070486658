import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';
import { IBeachChairBooking } from 'src/models/bookings/IBeachChairBooking';

interface IWidgetContext {
    bookedId: string;
    setBookedId: Dispatch<SetStateAction<string>>;
    bookedRef: string;
    setBookedRef: Dispatch<SetStateAction<string>>;
    beachChairs: IBeachChairBooking[];
    setBeachChairs: Dispatch<SetStateAction<IBeachChairBooking[]>>;
    price: number;
    setPrice: Dispatch<SetStateAction<number>>;
    totalPrice: number;
    setTotalPrice: Dispatch<SetStateAction<number>>;
}

const WidgetContext = createContext<IWidgetContext | undefined>(undefined);

export function WidgetContextProvider({ children }: { children: ReactNode }) {
    const [bookedId, setBookedId] = useState<string>('');
    const [bookedRef, setBookedRef] = useState<string>('');
    const [beachChairs, setBeachChairs] = useState<IBeachChairBooking[]>([]);
    const [price, setPrice] = useState<number>(0);
    const [totalPrice, setTotalPrice] = useState<number>(0);

    return (
        <WidgetContext.Provider
            value={{
                bookedId,
                setBookedId,
                bookedRef,
                setBookedRef,
                beachChairs,
                setBeachChairs,
                price,
                setPrice,
                totalPrice,
                setTotalPrice
            }}
        >
            {children}
        </WidgetContext.Provider>
    );
}

export function useWidgetState(): IWidgetContext {
    const state = useContext(WidgetContext);
    if (!state) throw new Error('WidgetProvider not found');
    return state;
}
