import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init(
    {
      fallbackLng: "de",
      ns: ["widget"],
      defaultNS: "widget",
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: false,
        wait: false,
      }
    },
    (err, t) => {}
  );

export default i18next;
