import { Box, CircularProgress } from '@material-ui/core';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import qs from 'qs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useGetPaymentResultPublic } from 'src/api/beachChairsAPI';
import { EBookingPaymentMethod } from 'src/models/bookings/EBookingPaymentMethod';
import { GERMANY_TIME_ZONE } from 'src/utils/constants';
import { currencyFormatter } from 'src/utils/currencyFormatter';
import styled from 'styled-components';

const BookingResultPanelBlock = styled.div`
    width: 100%;
    flex-direction: column;
    margin: auto;
    margin-top: 80px;
    max-width: 550px;

    .result-title {
        font-size: 36px;
        font-weight: 400;
        line-height: 42px;
        text-align: center;
        margin-bottom: 22px;
    }

    .result-content-text {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        margin-bottom: 22px;

        & .notification {
            font-size: 18px;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: left;
            margin-top: 24px;
        }
    }

    .ticket-id-title {
        font-size: 36px;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 15px;
    }

    .bank-notification-text {
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
    }

    .container {
        display: flex;
        justify-content: space-between;

        .row {
            border-bottom: 1px solid black;
            font-size: 16px;
            display: flex;
            justify-content: space-between;
            width: 250px;
            height: 35px;
            align-items: center;

            .row-title,
            .row-content {
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;

                &.sum {
                    font-weight: 700;
                }
            }
        }

        &.price-sum {
            margin-top: 30px;
            margin-bottom: 40px;
        }
    }
`;

interface IProps {
    sample?: string;
}

const BookingResultPanel: React.FC<IProps> = () => {
    const { t } = useTranslation(['common', 'widget']);
    const location = useLocation();

    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true,
    });

    const { isLoading, data } = useGetPaymentResultPublic(
        typeof query?.email === 'string' ? query.email : '',
        typeof query?.bookingRef === 'string' ? query.bookingRef : '',
    );

    return isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <CircularProgress />
        </Box>
    ) : !data?.bookingRef ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            {t('common:somethingWentWrongNotification')} <br />
            {t('common:tryAgain')}
        </Box>
    ) : (
        <BookingResultPanelBlock>
            <div className="result-title">{t('widget:bookingConfirmation')}</div>
            <div className="result-content-text">
                {t('widget:bookingResult.emailNotification')}

                <div className="notification">{t('widget:bookingResult.reservationNumber')}:</div>
            </div>
            <div className="ticket-id-title">{data?.bookingRef}</div>

            <div className="container">
                <div className="left">
                    <div className="row">
                        <div className="row-title">{t('widget:section')}:</div>
                        <div className="row-content">{data?.beachChairBookings[0]?.beachChair?.section?.value}</div>
                    </div>
                    <div className="row">
                        <div className="row-title">{t('widget:row')}:</div>
                        <div className="row-content">{data?.beachChairBookings[0]?.beachChair?.row?.value}</div>
                    </div>
                    <div className="row">
                        <div className="row-title">{t('widget:model')}:</div>
                        <div className="row-content">
                            {t('common:models.' + data?.beachChairBookings[0]?.beachChair?.model)}
                        </div>
                    </div>
                </div>
                <div className="right">
                    <div className="row">
                        <div className="row-title">{t('widget:pickup')}:</div>
                        <div className="row-content">
                            {data?.beachChairBookings[0]?.start
                                ? format(
                                      utcToZonedTime(new Date(data?.beachChairBookings[0]?.start), GERMANY_TIME_ZONE),
                                      'dd.MM.yyyy',
                                  )
                                : ''}
                        </div>
                    </div>
                    <div className="row">
                        <div className="row-title">{t('widget:dropoff')}:</div>
                        <div className="row-content">
                            {data?.beachChairBookings[0]?.end
                                ? format(
                                      utcToZonedTime(new Date(data?.beachChairBookings[0]?.end), GERMANY_TIME_ZONE),
                                      'dd.MM.yyyy',
                                  )
                                : ''}
                        </div>
                    </div>
                    <div className="row">
                        {/* <div className="row-title">{t('widget:duration')}:</div>
                        <div className="row-content">day-time(mockup)</div> */}
                    </div>
                </div>
            </div>
            <div className="container price-sum">
                <div className="right"></div>
                <div className="left">
                    <div className="row">
                        <div className="row-title">{t('widget:priceTerms.vat19')}:</div>
                        <div className="row-content sum">
                            {data?.totalPrice && currencyFormatter(data.totalPrice * 0.19 + '') + ' €'}
                        </div>
                    </div>
                    <div className="row">
                        <div className="row-title">{t('widget:priceTerms.price')}:</div>
                        <div className="row-content sum">
                            {data?.totalPrice && currencyFormatter(data?.totalPrice + '') + ' €'}
                        </div>
                    </div>
                </div>
            </div>
            {[EBookingPaymentMethod.BANK_TRANSFER, EBookingPaymentMethod.STRIPE_BANK_TRANSFER].includes(data?.paymentMethod as EBookingPaymentMethod) && (
                <div className="bank-notification-text">
                    {t('widget:bookingResult.bankNotification')}: <br /><br />
                    <strong>Kontoinhaber: </strong>
                    {data?.metaData?.bankData?.accountHolder || 'Strand & Mehr GmbH'}
                    <br />
                    <strong>BIC: </strong>
                    {data?.metaData?.bankData?.bic} - <strong>IBAN:</strong> {data?.metaData?.bankData?.iban}
                    <br />
                    <strong>Verwendungszweck: </strong>
                    {data?.metaData?.bankData?.reference}
                </div>
            )}
        </BookingResultPanelBlock>
    );
};

export default BookingResultPanel;
