import React from 'react';
import client from 'src/api/api';
import { ISections } from 'src/models/vendor/ISections';

export default function useSections(sectionId: any) {
    const [sectionData, setSectionData] = React.useState<ISections>();

    const getGetSectionById = React.useCallback(async () => {
        const response = await client.get(`/features/sections?filters%5Bid%5D=${sectionId}`);
        setSectionData(response.data);
    }, [sectionId]);

    React.useEffect(() => {
        if (sectionId !== 0 && sectionId !== undefined && (sectionId as any) !== '') {
            getGetSectionById();
        }
    }, [sectionId, getGetSectionById]);

    return { sectionData };
}
