import { Button } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const ErrorBoundaryBlock = styled.div`
    width: 100%;
    height: 100%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    flex-direction: column;

    Button {
        margin-top: 8px;
    }
`;

interface IProps {
    error: any;
    resetErrorBoundary: () => void;
}

const ErrorBoundary: React.FC<IProps> = ({ error, resetErrorBoundary }) => {
    const { t } = useTranslation('common');

    return (
        <ErrorBoundaryBlock>
            <p>{t('somethingWentWrongNotification')}:</p>
            <p>{error.message}</p>
            <Button onClick={resetErrorBoundary} variant="contained" color="primary">
                {t('tryAgain')}
            </Button>
        </ErrorBoundaryBlock>
    );
};

export default ErrorBoundary;
