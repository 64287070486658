import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import styled from 'styled-components';
import { DialogState } from '../../hook/DialogContext';

const ModalDialogBlock = styled(Dialog)`
    .MuiDialog-paper {
        min-width: 576px;
        background: #ffffff;
        border-radius: 20px;
        padding: 40px;
    }

    .dialog-title {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.8px;
        padding: 0;
    }

    .dialog-content {
        padding: 16px 0px;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.42px;

        .text {
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0.42px;
            margin-bottom: 16px;
        }
    }

    .dialog-btn {
        width: 120px;
        height: 40px;

        &:first-of-type {
            margin-right: 12px;
        }
    }

    .dialog-action {
        padding: 0;
    }
`;

export function ModalDialog(props: DialogState) {
    const handleClickPrimary = () => {
        if (props.primaryButton?.callback && props.primaryButton.callback() === false) {
            return;
        } else {
            if (props.onClose) props.onClose();
        }
    };

    const handleClickSecondary = () => {
        if (props.secondaryButton?.callback && props.secondaryButton.callback() === false) {
            return;
        } else {
            if (props.onClose) props.onClose();
        }
    };

    return (
        <ModalDialogBlock maxWidth={props.maxWidth} open={props.open} onClose={props.onClose} scroll="body">
            <DialogTitle className="dialog-title" disableTypography>
                {props.title}
            </DialogTitle>
            <DialogContent className="dialog-content">
                {typeof props.body === 'string' ? (
                    <DialogContentText className="text">{props.body}</DialogContentText>
                ) : (
                    props.body
                )}
            </DialogContent>
            {(props.primaryButton || props.secondaryButton) && (
                <DialogActions className="dialog-action">
                    {props.secondaryButton && (
                        <Button
                            className="dialog-btn"
                            onClick={handleClickSecondary}
                            color="secondary"
                            variant="contained"
                            disabled={props.secondaryButton.disabled}
                        >
                            {props.secondaryButton.text}
                        </Button>
                    )}
                    {props.primaryButton && (
                        <Button
                            className="dialog-btn"
                            onClick={handleClickPrimary}
                            color="primary"
                            variant="contained"
                            disabled={props.primaryButton.disabled}
                        >
                            {props.primaryButton.text}
                        </Button>
                    )}
                </DialogActions>
            )}
        </ModalDialogBlock>
    );
}
