import { Box, Button } from '@material-ui/core';
import { Popover, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MarkdownIt from 'markdown-it';
import { SectionContext, SectionContextType } from './sectionContext';
import { apiConfig } from 'src/config/config';

const path = `${apiConfig.apiPrefix}/vendor/locations/terms/`;

const Terms = () => {
    const { t } = useTranslation(['common', 'widget']);
    // const location = useLocation();
    const { sectionData } = React.useContext(SectionContext) as SectionContextType;
    // const [termsPath, setTermsPath] = useState("");
    const [terms, setTerms] = useState('');
    const [popOveContent, setpopOveContent] = useState('');
    const [PopOverHeadline, setPopOverHeadline] = useState('');
    const [priceText, setPriceText] = useState('');
    const [openPopOver, setOpenPopOver] = useState(false);

    const handleClose = () => {
        setOpenPopOver(false);
    };

    const handlePriceClick = () => {
        setOpenPopOver(true);
        setPopOverHeadline('Preise');
        setpopOveContent(`${priceText}`);
    };

    const handleTermsClick = () => {
        setOpenPopOver(true);
        setPopOverHeadline('');
        setpopOveContent(terms);
    };

    React.useEffect(() => {
        const md = new MarkdownIt();
        const locationObj = sectionData?.items[0]?.location;
        setPriceText(md.render(`${locationObj?.priceText?.de}`));
        // setTermsPath(`${path}${locationObj?.id}/pdf`);
        if (sectionData)
            fetch(`${path}${locationObj?.id}/html`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`This is an HTTP error: The status is ${response.status}`);
                    }
                    return response.json();
                })
                .then(actualData => {
                    setTerms(actualData.html);
                })
                .catch(err => {
                    console.log(err.message);
                });
    }, [sectionData]);

    return (
        <>
            <Popover
                id={`prices`}
                open={openPopOver}
                onClose={() => {
                    handleClose();
                }}
                anchorPosition={{ top: 200, left: 400 }}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
            >
                <Typography sx={{ p: 2, minWidth: '370px' }}>
                    <Box style={{ textAlign: 'center' }}>
                        <Button onClick={() => handleClose()}>Fenster Schließen</Button>
                    </Box>
                    <h3>{PopOverHeadline}</h3>
                    <Box dangerouslySetInnerHTML={{ __html: popOveContent }}></Box>
                </Typography>
            </Popover>
            { sectionData &&
            <Box style={{ fontSize: '13px' }}>
                {t('widget:price.textBegin')}
                <a
                    href="#price"
                    onClick={event => {
                        event.preventDefault();
                        handlePriceClick();
                    }}
                >
                    {t('widget:price.titlePrice')}
                </a>
                {t('widget:price.textMiddle')}
                <a
                    href="#agb"
                    onClick={event => {
                        event.preventDefault();
                        handleTermsClick();
                    }}
                >
                    {t('widget:price.titleTerms')}
                </a>
                {t('widget:price.textEnd')}
            </Box>
            }
        </>
    );
};

export default Terms;
