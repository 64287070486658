import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import deepEqual from 'deep-equal';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BASE_URL } from 'src/config/config';

const { CancelToken } = axios;

export interface AxiosResponseDataPagination {
    total_pages: number;
    total_elements: number;
    current_page: number;
    current_elements: number;
    total_page: number;
    total_count: number;
    list_count: number;
}

interface AxiosProps<P> {
    response: AxiosResponse<P> | null;
    error: AxiosError | null;
    isLoading: boolean;
}

export interface AxiosState<P> {
    data: P | undefined;
    response: AxiosResponse<P> | null;
    error: AxiosError | null;
    isLoading: boolean;
    refetch: (config?: AxiosRequestConfig) => Promise<AxiosResponse<P>>;
}

export function useAxios<P>(url: string, axiosConfig: AxiosRequestConfig = {}, manualFetch = false): AxiosState<P> {
    const [axiosState, setAxiosState] = useState<AxiosProps<P>>({
        response: null,
        error: null,
        isLoading: !manualFetch,
    });
    const configRef = useRef(axiosConfig);
    const manualFetchRef = useRef(manualFetch);
    const userToken = localStorage.getItem('user-token');

    const configEqual: boolean = useMemo(
        () => deepEqual(configRef.current, axiosConfig, { strict: true }),
        [axiosConfig],
    );
    if (!configEqual) configRef.current = axiosConfig;

    const fetch = useCallback(
        async (config: AxiosRequestConfig) => {
            setAxiosState(state => ({ ...state, isLoading: true, error: null }));
            let response = null;

            try {
                response = await axios({
                    baseURL: BASE_URL,
                    url,
                    ...config,
                    headers: userToken ? { Authorization: `Bearer ${userToken}` } : {},
                    cancelToken: CancelToken.source().token,
                });
                setAxiosState({ response, error: null, isLoading: false });
            } catch (error: any) {
                if (axios.isCancel(error)) {
                } else {
                    setAxiosState({ error, response: null, isLoading: false });
                }
                return error.message;
            }

            return response;
        },
        [userToken, url],
    );

    const refetch = useCallback(
        async (config?: AxiosRequestConfig) => {
            configRef.current = { ...configRef.current, ...config };
            return await fetch(configRef.current);
        },
        [fetch],
    );

    useEffect(() => {
        if (manualFetchRef.current) return;

        fetch(configRef.current);

        return () => {
            CancelToken.source().cancel('useEffect cleanup');
        };
    }, [fetch]);

    const { response, error, isLoading } = axiosState;
    const data = response?.data;

    return { data, response, error, isLoading, refetch };
}
