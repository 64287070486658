import React from 'react';
import { PayPalButton } from 'react-paypal-button-v2';
import { useHistory } from 'react-router-dom';
import { putBookingConfirmPublic, updateBeachChairBooking } from 'src/api/beachChairsAPI';
import { EBookingPaymentMethod } from 'src/models/bookings/EBookingPaymentMethod';
import { GERMANY_TIME_ZONE } from 'src/utils/constants';
import styled from 'styled-components';
import { useWidgetState } from '../views/widget/WidgetContext';
// import qs from 'qs';

const CardElementBlock = styled.div`
    .component-frame {
        color: white;
    }
`;

interface IProps {
    price: number;
}

const PayPalSection: React.FC<IProps> = ({ price }) => {
    const history = useHistory();
    const { bookedId } = useWidgetState();
    // const location = useLocation();
    // const query = qs.parse(location.search, {
    //     ignoreQueryPrefix: true,
    // });

    return (
        <label>
            <CardElementBlock>
                <PayPalButton
                    amount={price}
                    // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                    onSuccess={async (details: { payer: { name: { given_name: string } } }, data: { orderID: any }) => {
                        alert('Transaction completed by ' + details.payer.name.given_name);
                        // OPTIONAL: Call your server to save the transaction
                        const { data: bookingData } = await updateBeachChairBooking(bookedId, {
                            paymentMethod: EBookingPaymentMethod.PAYPAL,
                        });
                        const { data: confirmedBookingData } = await putBookingConfirmPublic(
                            bookingData.customer?.email ?? '',
                            bookingData.bookingRef,
                            GERMANY_TIME_ZONE,
                        );
                        history.push(
                            `/beach-chairs/result/query?email=${confirmedBookingData?.customer?.email}&bookingRef=${confirmedBookingData?.bookingRef}`,
                        );
                    }}
                    style={{ color: 'blue' }}
                />
            </CardElementBlock>
        </label>
    );
};
export default PayPalSection;
