import React, { ComponentType } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import CustomizedErrorBoundary from 'src/components/common/ErrorBoundary';

function withErrorBoundary<P>(WrappedComponent: ComponentType<P>): React.FC<P> {
    const WithComponent = (props: P) => {
        return (
            <ErrorBoundary FallbackComponent={CustomizedErrorBoundary} onReset={() => window.location.reload()}>
                <WrappedComponent {...(props as P)} />
            </ErrorBoundary>
        );
    };

    return WithComponent;
}

export default withErrorBoundary;
