import { createContext, Dispatch, ReactNode, useContext, useEffect, useReducer } from 'react';
import { ModalDialog } from 'src/components/dialog/ModalDialog';

export interface DialogState {
    open: boolean;
    title?: ReactNode;
    body?: ReactNode;
    onClose?: () => void;
    primaryButton?: {
        text: string;
        callback?: () => boolean | void | Promise<boolean | void>;
        disabled?: boolean;
    };
    secondaryButton?: {
        text: string;
        callback?: () => boolean | void | Promise<boolean | void>;
        disabled?: boolean;
    };
    /**
     * Determine the max-width of the dialog.
     * The dialog width grows with the size of the screen.
     * Set to `false` to disable `maxWidth`.
     */
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

type Action =
    | { type: 'INIT'; options?: Omit<DialogState, 'open'> }
    | { type: 'SHOW'; options?: Omit<DialogState, 'open'> }
    | { type: 'CHANGE'; options?: Omit<DialogState, 'open'> }
    | { type: 'CLOSE' };

type DialogDispatch = Dispatch<Action>;
const DialogDispatchContext = createContext<DialogDispatch | undefined>(undefined);

function dialogReducer(state: DialogState, action: Action): DialogState {
    switch (action.type) {
        case 'INIT':
            return { open: false, ...action.options };
        case 'SHOW':
            return { open: true, ...action.options };
        case 'CHANGE':
            return { ...state, ...action.options };
        case 'CLOSE':
            return { ...state, open: false };
        default:
            throw new Error('Unhandled action');
    }
}

export function DialogContextProvider({ children }: { children: ReactNode }) {
    const [state, dispatch] = useReducer(dialogReducer, { open: false, title: '', body: '' });

    const handleClose = () => {
        state.onClose && state.onClose();
        dispatch({ type: 'CLOSE' });
    };

    return (
        <DialogDispatchContext.Provider value={dispatch}>
            {children}
            <ModalDialog {...state} onClose={handleClose} />
        </DialogDispatchContext.Provider>
    );
}

function useDialogDispatch() {
    const dispatch = useContext(DialogDispatchContext);
    if (!dispatch) throw new Error('dialogProvider not found');
    return dispatch;
}

export function useDialog(props?: Omit<DialogState, 'open'>) {
    const dispatch = useDialogDispatch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => dispatch({ type: 'INIT', options: props }), [dispatch]);

    return {
        showDialog: (props?: Omit<DialogState, 'open'>) => dispatch({ type: 'SHOW', options: props }),
        changeDialog: (props?: Omit<DialogState, 'open'>) => dispatch({ type: 'CHANGE', options: props }),
        closeDialog: () => dispatch({ type: 'CLOSE' }),
        showSimpleDialog: (title: ReactNode, body: ReactNode) =>
            dispatch({ type: 'SHOW', options: { title, body, primaryButton: { text: 'OK' } } }),
    };
}
