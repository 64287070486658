import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import React from 'react';
import BeachMapState from './BeachMapState';
import { EMapState, MyMapContext, MyMapContextType } from './mapContext';
import RowMapState from './RowMapState';

export interface IMapStateProps {
    showMapMarkers: boolean;
}

const Maps = ({ showMapMarkers }: IMapStateProps) => {
    const { mapState, rowsReady } = React.useContext(MyMapContext) as MyMapContextType;
    const [map, setMap] = React.useState<google.maps.Map>();
    const [mapBounds, setMapBounds] = React.useState<google.maps.LatLngBounds>();
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: encodeURIComponent('AIzaSyBWGae4Fpv0p0n-llM6Qysj4r_CvvhzIkw'),
        language: 'DE',
    });

    React.useEffect(() => {
        if (map && mapBounds) {
            map.fitBounds(mapBounds, 50);
        }
    }, [mapBounds, map]);

    const renderMap = React.useCallback(() => {
        const onLoad = (map: any) => {
            setMap(map);
        };

        const onUnmount = (map: any) => {
            setMap(undefined);
        };

        return (
            <GoogleMap
                onLoad={onLoad}
                options={{
                    disableDefaultUI: true,
                    zoomControl: true,
                    streetViewControl: false,
                    clickableIcons: false,
                    mapTypeControl: true,
                }}
                mapContainerStyle={{
                    width: '400px',
                    height: '300px',
                }}
                zoom={15}
                //center={{ lat: 10.1201516, lng: 54.333523 }}
                onUnmount={onUnmount}
                id="map"
            >
                {!rowsReady && (
                    <BeachMapState
                        setMapBounds={setMapBounds}
                        showMapMarkers={showMapMarkers}
                    />
                )}
                {mapState === EMapState.ROWS && (
                    <RowMapState
                        setMapBounds={setMapBounds}
                        showMapMarkers={showMapMarkers}
                    />
                )}
            </GoogleMap>
        );
        // eslint-disable-next-line
    }, [mapState, rowsReady]);

    return isLoaded ? renderMap() : <></>;
};

export default React.memo(Maps);
