import * as React from 'react';
import { createContext } from 'react';

export enum EMapState {
    SECTION = 'SECTION',
    ROWS = 'ROWS',
}

export type MyMapContextType = {
    mapState: EMapState;
    setState: React.Dispatch<React.SetStateAction<EMapState>>;
    rowsReady: boolean;
    setRowsReady: React.Dispatch<React.SetStateAction<boolean>>;
    hasSubsections: boolean;
    setHasSubsections: React.Dispatch<React.SetStateAction<boolean>>;
    publicReference: string;
    setPublicReference: React.Dispatch<React.SetStateAction<string>>;
    beachId: number;
    setBeachId: React.Dispatch<React.SetStateAction<number>>;
    cityId: number;
    setCityId:React.Dispatch<React.SetStateAction<number>>;
};

export const MyMapContext = createContext<MyMapContextType | undefined>(undefined);

type MapProviderProps = { children: React.ReactNode };

function MapContextProvider({ children }: MapProviderProps) {
    const [mapState, setState] = React.useState(EMapState.SECTION);
    const [rowsReady, setRowsReady] = React.useState(false);
    const [hasSubsections, setHasSubsections] = React.useState(false);
    const [publicReference, setPublicReference] = React.useState('');
    const [beachId, setBeachId] = React.useState(0);
    const [cityId, setCityId] = React.useState(0);

    const value = {
        mapState,
        setState,
        rowsReady,
        setRowsReady,
        hasSubsections,
        setHasSubsections,
        publicReference,
        setPublicReference,
        beachId,
        setBeachId,
        cityId,
        setCityId
    };
    return <MyMapContext.Provider value={value}>{children}</MyMapContext.Provider>;
}

export { MapContextProvider };
