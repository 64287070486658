import { AxiosResponse } from 'axios';
import { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ModalContext: any = createContext(undefined);

export function ModalContextProvider({ children }: { children: ReactNode }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const { t } = useTranslation('common');
    const [leftButtonText, setLeftButtonText] = useState(t('no'));
    const [rightButtonText, setRightButtonText] = useState(t('yes'));

    const onModalOpenHandler = useCallback(
        (message, leftButtonMessage?, rightButtonMessage?) => {
            setModalMessage(message);
            leftButtonMessage && setLeftButtonText(leftButtonMessage);
            rightButtonMessage && setRightButtonText(rightButtonMessage);
            setIsModalOpen(true);
        },
        [setIsModalOpen],
    );

    const onModalCloseHandler = useCallback(() => {
        setIsModalOpen(false);
    }, [setIsModalOpen]);

    const onModalExecuteHandler = useCallback((handler: () => Promise<AxiosResponse>) => {
        handler();
    }, []);

    return (
        <ModalContext.Provider
            value={{
                isModalOpen,
                setIsModalOpen,
                modalMessage,
                onModalOpenHandler,
                leftButtonText,
                setLeftButtonText,
                rightButtonText,
                setRightButtonText,
                onModalExecuteHandler,
                onModalCloseHandler,
            }}
        >
            {children}
        </ModalContext.Provider>
    );
}

export function useModalState(): any {
    const state = useContext(ModalContext);
    if (!state) throw new Error('ModalProvider not found');
    return state;
}
