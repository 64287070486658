import { AlertDialogState } from 'src/hook/AlertDialogContext';
import {
    createStyles,
    makeStyles,
    Theme,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    IconButton,
    Box,
} from '@material-ui/core';
import styled from 'styled-components';

import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/ErrorOutline';

const AlertDialogBlock = styled(Dialog)`
    .MuiDialog-paper {
        margin: auto;
        width: 382px;
        background-color: #1a263b;
        border-radius: 12px;
    }

    .titleBox {
        display: flex;
        height: 48;
        padding: 12px 14px 12px 20px;
        background-color: #1a263b;
    }
    .title {
        font-size: 1.125rem;
        font-weight: 500;
        letter-spacing: 0.9;
        margin-left: 12px;
        padding: 0;
        color: white;
    }

    .iconClose {
        width: 24;
    }

    .body {
        padding: 13px 20px 0;
        color: white;
        font-size: 0.875rem;
        line-height: 1.2px;
        overflow-y: visible;
    }

    .body-text {
        color: white;
        font-size: 0.875rem;
        line-height: 1.29;
        margin-bottom: 2;
    }

    .button {
        width: 162;
        height: 40;
        padding: '6px 11px';
    }

    .button-second {
        width: 162;
        height: 40;
        padding: '6px 11px';
        color: #ffffff;

        &:hover {
            opacity: 0.7;
        }
    }
`;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        action: {
            padding: 20,
        },
        actionSoloBtn: {
            padding: 20,
            justifyContent: 'flex-end',
        },
        dialogActionSpacing: {
            '&>:not(:first-child)': {
                marginLeft: 18,
            },
        },
    }),
);

export function AlertDialog(props: AlertDialogState) {
    const classes = useStyles();

    const handleClickClose = () => {
        if (props.onClose) props.onClose();
    };

    const handleClickPrimary = async () => {
        if (props.primaryButton?.callback && (await props.primaryButton.callback()) === false) {
            return;
        } else {
            if (props.onClose) props.onClose();
        }
    };

    const handleClickSecondary = async () => {
        if (props.secondaryButton?.callback && (await props.secondaryButton.callback()) === false) {
            return;
        } else {
            if (props.onClose) props.onClose();
        }
    };

    return (
        <AlertDialogBlock
            maxWidth={props.maxWidth}
            open={props.open}
            onClose={props.onClose}
            scroll="body"
            disableBackdropClick
        >
            <Box className="titleBox">
                {props.alertIconType === 'ERROR' ? (
                    <WarningIcon style={{ width: 24, height: 24, fill: '#e4cd4e' }} />
                ) : (
                    <InfoIcon style={{ width: 24, height: 24, fill: '#47ce6f' }} />
                )}

                <DialogTitle className="title" disableTypography>
                    {props.title}
                </DialogTitle>
                <Box flex="1 1 auto" />
                <IconButton className="iconClose" onClick={handleClickClose} size="small">
                    <CloseIcon style={{ height: 12, width: 12 }} />
                </IconButton>
            </Box>
            <DialogContent className="body">
                {typeof props.body === 'string' ? (
                    <DialogContentText className="body-text">{props.body}</DialogContentText>
                ) : (
                    props.body
                )}
            </DialogContent>
            {(props.primaryButton || props.secondaryButton) && (
                <DialogActions
                    className={props.secondaryButton ? classes.action : classes.actionSoloBtn}
                    classes={{
                        spacing: classes.dialogActionSpacing,
                    }}
                >
                    {props.secondaryButton && (
                        <Button
                            className="button-second"
                            onClick={handleClickSecondary}
                            variant="contained"
                            color="secondary"
                            disableElevation
                        >
                            {props.secondaryButton.text}
                        </Button>
                    )}
                    {props.primaryButton && (
                        <Button
                            className="button"
                            onClick={handleClickPrimary}
                            color="primary"
                            variant="contained"
                            disableElevation
                        >
                            {props.primaryButton.text}
                        </Button>
                    )}
                </DialogActions>
            )}
        </AlertDialogBlock>
    );
}
