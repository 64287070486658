import { useAxios } from 'src/hook/useAxios';
import { IBeachChairAvailableRow } from 'src/models/beachChairs/IBeachChairAvailableRow';
import { EBookingPaymentMethod } from 'src/models/bookings/EBookingPaymentMethod';
import { IBooking } from 'src/models/bookings/IBooking';
import { IFilteredBeachChairData } from 'src/models/interface/filtered-beach-chair-data.interface';
import { ISections } from 'src/models/vendor/ISections';
import { dateDiffInDays } from 'src/utils/getDateDiffInDays';

import client from './api';

const language = 'de';
const baseUrlBookings = '/bookings/bookings';
const baseUrlFeatures = '/features';
const baseUrlBooking = '/bookings';
const baseUrlPublicBooking = '/features/public';

export interface CreateBeachChairBookingInput {
    beachChairId: number;
    start: string;
    end: string;
    model: string;
    rate?: string;
    status?: string;
}

export interface CreateBeachChairBookingCustomerReturn {
    beachChairId: number;
    start: string;
    end: string;
    price: number;
    id: number;
    email: string;
}

export interface UpdateBeachChairBookingData {
    status?: string;
    totalPrice?: number;
    comment?: string;
    customerId?: number;
    paymentMethod?: string;
    bankDetails?: {
        bic?: string;
        iban?: string;
        accountHolder?: string;
        bankName?: string;
    };
    invoiceNumber?: string;
}

export interface IVendor {
    paymentMethods?: EBookingPaymentMethod[];
}


export const useGetSections = () => {
    return useAxios<ISections>(`/features/sections`);
};

export const useFilteredBeachChairDataByVendor = (vendorId: number) => {
    return useAxios<IFilteredBeachChairData>(`/features/filters/beach_chairs?vendorId=${vendorId}`);
};

export const useFilteredBeachChairDataByVendorReferencePublic = (publicReference: string) => {
    return useAxios<IFilteredBeachChairData>(
        publicReference || publicReference !== ``
            ? `/features/public/filters/beach_chairs?publicReference=${publicReference}`
            : ``,
    );
};

export const useFeaturesSections = (cityId?: number, beachId?: number, publicReference?: string) => {
    return useAxios<ISections>(
        `/features/sections?${
            publicReference
                ? `filters[publicReference]=${publicReference}`
                : `${beachId ? `filters[beachId]=${beachId}` : ``}${cityId ? `filters[cityId]=${cityId}` : ``}`
        }`,
        { method: 'GET' },
    );
};

export const createBeachChairBookingCustomer = (bookingId: string, data: { id: number }) => {
    return client.post<CreateBeachChairBookingCustomerReturn>(`${baseUrlBooking}/bookings/${bookingId}/customer`, data);
};
export const updateBeachChairBooking = (bookingId: string, data: UpdateBeachChairBookingData) => {
    return client.patch<IBooking>(`${baseUrlBooking}/bookings/${bookingId}`, data);
};

export const updateBeachChairBookingPublic = (email: string, bookingRef: string, data: UpdateBeachChairBookingData) => {
    const encodedBookingRef = encodeURIComponent(bookingRef);
    return client.put<IBooking>(`${baseUrlPublicBooking}/booking?email=${email}&bookingRef=${encodedBookingRef}`, data);
};

export const useCreateBooking = () => {
    return useAxios<IBooking>(`${baseUrlBookings}`, { method: 'POST' }, true);
};

export const useCreateBookingPublic = (publicReference: string) => {
    return useAxios<IBooking>(
        `${baseUrlFeatures}/public/booking?publicReference=${publicReference}`,
        { method: 'POST' },
        true,
    );
};

export const useCreateCustomer = () => {
    return useAxios<any>(`${baseUrlBooking}/customers`, { method: 'POST' }, true);
};

export const useCreateCustomerPublic = (bookingRef: string, publicReference: string) => {
    return useAxios<any>(
        `${baseUrlFeatures}/public/booking/${bookingRef}/add_customer?publicReference=${publicReference}`,
        { method: 'POST' },
        true,
    );
};

export const createBeachChairBooking = (bookingId: number, data: CreateBeachChairBookingInput) => {
    return client.post(`${baseUrlFeatures}/booking_details/${bookingId}/beach_chair_bookings`, data);
};

export const createBeachChairBookingPublic = (
    bookingRef: string,
    publicReference: string,
    data: CreateBeachChairBookingInput,
) => {
    return client.post<IBooking>(
        `${baseUrlFeatures}/public/booking/${bookingRef}/beach_chair_bookings?publicReference=${publicReference}`,
        data,
    );
};

export const deleteBeachChairBookingPublic = (
    bookingRef: string,
    publicReference: string,
    beachChairBookingId: number,
) => {
    return client.delete<IBooking>(
        `${baseUrlFeatures}/public/booking/${bookingRef}/beach_chair_booking/${beachChairBookingId}?publicReference=${publicReference}`,
    );
};

export const putBookingConfirm = (bookingId: string, timeZone: string) => {
    const config = { headers: { 'Content-Type': 'application/json', 'x-custom-lang': language } };
    return client.put<IBooking>(
        `${baseUrlFeatures}/booking_details/${bookingId}/confirm/?timeZone=${timeZone}`,
        {},
        config,
    );
};

export const putBookingConfirmPublic = (email: string, bookingRef: string, timeZone: string) => {
    const encodedBookingRef = encodeURIComponent(bookingRef);
    return client.put<IBooking>(
        `${baseUrlFeatures}/public/booking/confirm?email=${email}&bookingRef=${encodedBookingRef}&timeZone=${timeZone}`,
    );
};

export const useGetPaymentResult = (bookedId: string) => {
    return useAxios<IBooking>(`${baseUrlFeatures}/booking_details/${bookedId}`);
};

export const useGetPaymentResultPublic = (email: string, bookingRef: string) => {
    const encodedBookingRef = encodeURIComponent(bookingRef);
    return useAxios<IBooking>(
        `${baseUrlFeatures}/public/booking_details?email=${email}&bookingRef=${encodedBookingRef}`,
    );
};

export const useGetAvailableBeachChairs = (startDate: string, endDate: string, sectionId: string, timeZone: string) => {    
    return useAxios<{ name: string; rowData: IBeachChairAvailableRow[] }[]>(
        `${baseUrlFeatures}/sections/${sectionId}/beach_chairs_availability?start=${startDate}&end=${endDate}&row=&free=true&timeZone=${timeZone}`,
        {},
        true,
    );
};

export const useGetAvailableBeachChairsPublic = (
    startDate: string,
    endDate: string,
    sectionId: string,
    timeZone: string,
    publicReference: string,
) => {
    return useAxios<{ geoJson: object; name: string; id: number; rowData: IBeachChairAvailableRow[] }[]>(
        sectionId !== `` ? `${baseUrlFeatures}/public/sections/${sectionId}/beach_chairs_availability?start=${startDate}&end=${endDate}&row=&free=true&timeZone=${timeZone}&publicReference=${publicReference}`: ``,
        {},
    );
};

export const useGetCalculation = (
    sectionId: string,
    rowId: string,
    model: string,
    start: string,
    end: string,
    publicReference: string,
) => {
    return useAxios<{
        items: {
            conditions: { durations: string[]; hours: string[]; default: string[]; offerHours: string[] };
            bookingStart: string;
            bookingEnd: string;
            offerStart: string;
            offerEnd: string;
            id: number;
            vendorId: number;
        }[];
        count: number;
    }>(
        sectionId !== '' &&
            rowId !== '' &&
            model !== '' &&
            start !== '' &&
            end !== '' &&
            publicReference !== '' &&
            sectionId !== undefined &&
            rowId !== undefined &&
            model !== undefined &&
            start !== undefined &&
            end !== undefined &&
            publicReference !== undefined &&
            dateDiffInDays(new Date(start), new Date(end)) > 0
            ? `/prices/calculate?sectionId=${sectionId}&rowId=${rowId}&model=${model}&start=${start}&end=${end}&publicReference=${publicReference}`
            : '',
    );
};

export const useGetRates = (
    sectionId: string,
    rowId: string,
    model: string,
    start: string,
    end: string,
    publicReference: string,
) => {
    return useAxios<{ type: string; price: number; range?: string }[]>(
        `/prices/rates?sectionId=${sectionId}&rowId=${rowId}&model=${model}&start=${start}&end=${end}&publicReference=${publicReference}`,
        {},
        true,
    );
};
