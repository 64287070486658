import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { ButtonBase, ButtonGroup } from '@material-ui/core';
import { currencyFormatter } from 'src/utils/currencyFormatter';
import { useTranslation } from 'react-i18next';
import { dateDiffInDays } from 'src/utils/getDateDiffInDays';

const TicketButtonGroupBlock = styled.div`
    .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        height: 27px;
    }
    .label {
        margin-bottom: 3px;
        font-size: 14px;
        font-weight: 400;
        color: black;
        & .required-star {
            color: #ff0000;
        }
    }

    .helper {
        font-size: 14px;
        font-weight: 400;
        color: #ff0000;
    }
    .ticket-button-container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        & > * + * {
            margin-left: 8px !important;
        }
    }

    .ticketSelect-button {
        background-color: #e2e2e2;
        height: 64px;
        min-width: 127px;
        display: flex;
        flex-direction: column;
        padding: 4px;
        flex: 1;
        padding-left: 16px;
        padding-right: 16px;

        & .price {
            font-size: 16px;
            width: 100%;
            text-align: right;
        }
        & .ticket-name {
            font-size: 10px;
            width: 100%;
            text-align: left;
        }
        & .ticket-time {
            color: grey;
            margin-left: 4px;
            font-size: 8px;
        }
        &.selected-ticket {
            opacity: 1;
            background-color: #bebebe;
        }
    }
`;

interface IProps {
    label?: string;
    className?: string;
    placeholder?: string;
    name: string;
    readOnly?: boolean;
    rules?: RegisterOptions;
    required?: boolean;
    priceData?: { type: string; price:number, range?: string; }[];
    selectedDatesPrice?: number;
    startDate: number;
    endDate: number;
}

const TicketButtonGroup: React.FC<IProps> = ({
    className,
    placeholder,
    name,
    rules,
    readOnly,
    label,
    required,
    startDate,
    endDate,
    priceData,
    selectedDatesPrice,
    ...props
}) => {
    const { control, setValue } = useFormContext();
    const [selectedTicket, setSelectedTicket] = useState<boolean[]>([false, false, false, false]);
    const { t } = useTranslation(['widget']);
    const onSelectTicketHandler = useCallback((index: number) => {
        setSelectedTicket(prev => prev.map((_selected, ticketIndex) => ticketIndex === index));
    }, []);

    React.useEffect(() => {
        if (selectedDatesPrice !== 0) {
            onSelectTicketHandler(3);
            setValue('startDate', startDate);
            setValue('leaveDate', endDate);
            setValue('rate', 'none');
        } else if (priceData && Object.keys(priceData).length === 1) {
            Object.entries(priceData!).forEach((element, index) => {
                onSelectTicketHandler(index);
                setValue('rate', element[index]);
                setValue('startTime', '08:00:00.000Z');
                setValue('endTime', '18:00:00.000Z');
            });
        }
        // eslint-disable-next-line
    }, [priceData, selectedDatesPrice]);

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field, fieldState: { error }, formState: { dirtyFields } }) => (
                <TicketButtonGroupBlock>
                    <div className="header">
                        {label && (
                            <label className="label">
                                {label}
                                {required && <span className="required-star">*</span>}
                            </label>
                        )}
                        <span className="helper">{error ? error?.message || 'Invalid' : dirtyFields?.[name]}</span>
                    </div>

                    <ButtonGroup className="ticket-button-container" {...field} {...props}>
                        {dateDiffInDays(new Date(startDate), new Date(endDate)) < 1
                            ? priceData &&
                              priceData.length !== 0 &&
                              priceData.map((element, index) => (
                                  <ButtonBase
                                      className={clsx(
                                          'ticketSelect-button',
                                          selectedTicket[index] && 'selected-ticket',
                                      )}
                                      onClick={() => {
                                          onSelectTicketHandler(index);
                                          setValue('rate', element.type);
                                          setValue('startTime', '08:00:00.000Z');
                                          setValue('endTime', '18:00:00.000Z');
                                      }}
                                  >
                                      <div className="ticket-name">{t(`durations.${element.type}`)}</div>
                                      <div className="price">{currencyFormatter(element.price + '') + ' €'} </div>
                                  </ButtonBase>
                              ))
                            : selectedDatesPrice !== 0 && (
                                  <ButtonBase
                                      className={clsx('ticketSelect-button', selectedTicket[3] && 'selected-ticket')}
                                      onClick={() => {
                                          onSelectTicketHandler(3);
                                          setValue('startDate', startDate);
                                          setValue('leaveDate', endDate);
                                          setValue('rate', 'none');
                                      }}
                                  >
                                      <div className="ticket-name">{t('selected-dates-price')}</div>
                                      <div className="price">{selectedDatesPrice},00 €</div>
                                  </ButtonBase>
                              )}
                    </ButtonGroup>
                </TicketButtonGroupBlock>
            )}
        />
    );
};

export default TicketButtonGroup;
