import { Input } from '@material-ui/core';
import React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

const FormInputBlock = styled.div`
    width: 100%;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .label {
        margin-bottom: 3;
        font-size: 14px;
        font-weight: 400;
        color: black;
        & .required-star {
            color: #ff0000;
        }
    }

    .helper {
        font-size: 14px;
        font-weight: 400;
        color: #ff0000;
    }
`;

interface IProps {
    className?: string;
    placeholder?: string;
    name: string;
    readOnly?: boolean;
    rules?: RegisterOptions;
    label?: string;
    required?: boolean;
    validationMessage?: string;
    disabled? :boolean;
}

const FormInput: React.FC<IProps> = ({
    className,
    placeholder,
    name,
    rules,
    readOnly,
    label,
    validationMessage,
    disabled,
    required,
    ...props
}) => {
    const { control } = useFormContext();

    return (
        <FormInputBlock>
            <Controller
                render={({ field, fieldState: { error }, formState: { dirtyFields } }) => (
                    <>
                        <div className="header">
                            {label && (
                                <label className="label">
                                    {label}
                                    {required && <span className="required-star">*</span>}
                                </label>
                            )}
                            <span className="helper">
                                {error ? error?.message || 'Invalid' : dirtyFields?.[name] && validationMessage}
                            </span>
                        </div>
                        <Input
                            {...field}
                            placeholder={placeholder}
                            readOnly={readOnly}
                            fullWidth
                            error={!!error}
                            disableUnderline
                            disabled={disabled}
                            className={className}
                            style={{ paddingRight: 12, paddingLeft: 12, backgroundColor: '#e2e2e2' }}
                            value={field.value || ''}
                            {...props}
                        />
                    </>
                )}
                name={name}
                rules={rules}
                control={control}
            />
        </FormInputBlock>
    );
};

export default FormInput;
