const {
    REACT_APP_PUBLIC_URL,
    REACT_APP_API_PREFIX,
  } = process.env;

  const CONFIG_DATA = JSON.parse(
    // @ts-ignore
    decodeURIComponent(window["SERVER_DATA"] || "%7B%7D")
  ) as {
    PUBLIC_URL: string;
    API_PREFIX: string;
  };

  export const apiConfig = {
    publicUrl: CONFIG_DATA.PUBLIC_URL || REACT_APP_PUBLIC_URL,
    apiPrefix: CONFIG_DATA.API_PREFIX || REACT_APP_API_PREFIX,
  };

export const BASE_URL = apiConfig.apiPrefix;