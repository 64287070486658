/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@material-ui/core';
import uniqBy from 'lodash/uniqBy';
import qs from 'qs';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
    createBeachChairBookingPublic,
    deleteBeachChairBookingPublic,
    useCreateBookingPublic,
    useFeaturesSections,
    useFilteredBeachChairDataByVendorReferencePublic,
    useGetCalculation,
    useGetRates,
} from 'src/api/beachChairsAPI';
import LoadingButton from 'src/components/common/button/LoadingButton';
import FormDatePicker from 'src/components/form/FormDatePicker';
import FormDropdown from 'src/components/form/FormDropdown';
import { useAlertDialog } from 'src/hook';
import { GERMANY_TIME_ZONE } from 'src/utils/constants';
import messageSet from 'src/utils/message';
import TicketButtonGroup from './TicketButtonGroup';
import { useWidgetState } from './WidgetContext';
import { dateDiffInDays } from 'src/utils/getDateDiffInDays';
import Maps from './Maps';
import { EMapState, MyMapContext, MyMapContextType } from './mapContext';
import { SectionContext, SectionContextType } from './sectionContext';
import { Button, IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { currencyFormatter } from 'src/utils/currencyFormatter';
import { dateToProperFormat } from 'src/utils/dateFormat';
import Terms from './Terms';
import currency from 'currency.js';
import { IBeachChairAvailableRow } from 'src/models/beachChairs/IBeachChairAvailableRow';
import client from 'src/api/api';

const SelectTicketPanelBlock = styled.div<{ isShown: boolean }>`
    display: ${props => (props.isShown ? 'flex' : 'none')};
    width: 100%;
    flex-direction: column;
    margin: auto;
    margin-top: 80px;
    max-width: 550px;

    .main-text {
        font-size: 36px;
    }

    .sub-text {
        font-size: 12px;
    }

    .ticketSelect-button {
        background-color: #e2e2e2;
        height: 64px;
        min-width: 127px;
        display: flex;
        flex-direction: column;
        padding: 4px;
        flex: 1;
        padding-left: 16px;
        padding-right: 16px;

        & .price {
            font-size: 16px;
            width: 100%;
            text-align: right;
        }
        & .ticket-name {
            font-size: 10px;
            width: 100%;
            text-align: left;
        }
        & .ticket-time {
            color: grey;
            margin-left: 4px;
            font-size: 8px;
        }
        &.selected-ticket {
            opacity: 1;
            background-color: #bebebe;
        }
    }

    .row-grid {
        display: grid;
        gap: 8px;
        grid-template-rows: auto auto;
        grid-template-columns: minmax(50px, 271px) minmax(50px, 271px);
    }

    .row-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 14px;

        & > * {
            flex: 1;
        }

        & > * + * {
            margin-left: 10px;
        }
    }
    .title-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 34px;
    }
    .next-btn-container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-end;
        margin-top: 5px;
    }
    .tickets-grid {
        display: grid;
        width: 100%;
        align-items: center;
        justify-content: center;
        grid-template-columns: 50% 50%;
    }
    .add-icon {
        margin-top: 16px;
        gap: 5px;
        height: 64px;
        margin-left: 10px;
        font-size: 14px;
        align-self: center;
        color: #233f91;
        hover: {
            backgroundcolor: 'none';
        }
    }
    .remove-icon {
        align-self: center;
    }
    .added-beach-chair {
        font-size: 13px;
        padding: 10px;
        display: grid;
        margin-top: 10px;
        width: calc(100% -10px);
        background-color: #e2e2e2;
        align-items: center;
        justify-items: center;
        grid-template-columns: 10% 30% 45% 10% 5%;
        margin-bottom: 5px;
    }
    .total-price {
        font-weight: bold;
        margin-right: 20px;
    }
    .errorMessage {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        color: #ed6b17;
        font-weight: bold;
    }
`;

const alphabeticCompare = (a: any, b: any) => {
    if (a.name > b.name) {
        return 1;
    }
    if (b.name > a.name) {
        return -1;
    }
    return 0;
};

interface ISelectTicketPanelProps {
    isShown: boolean;
    shownTrigger: () => void;
}

const Price = (price: number) => {
    const currencyOptions = { symbol: '€', separator: '', decimal: ',' };
    return (
        currency(price).format(currencyOptions) +
        ' / inkl. ' +
        currency(price).subtract(currency(price).divide(1.19)).format(currencyOptions) +
        ' MwSt.'
    );
};

const SelectTicketPanel = ({ isShown, shownTrigger }: ISelectTicketPanelProps) => {
    const { t } = useTranslation(['widget', 'common']);
    const location = useLocation();
    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true,
    });
    const { setRowsReady, setState, setBeachId, setCityId, setPublicReference, publicReference } = React.useContext(
        MyMapContext,
    ) as MyMapContextType;
    const { setSectionId, sectionData, sectionId } = React.useContext(SectionContext) as SectionContextType;
    const beachId = typeof query?.beachId === 'string' ? query.beachId : '0';
    const cityId = typeof query?.cityId === 'string' ? query.cityId : '0';
    const mainPublicReference = typeof query?.publicReference === 'string' ? query.publicReference : publicReference;

    const [beachChairsData, setBeachChairsData] = React.useState<
        { geoJson: object; name: string; id: number; rowData: IBeachChairAvailableRow[] }[]
    >([]);
    const [errorObject, setErrorObject] = React.useState<any>();

    useEffect(() => {
        if (sectionId && sectionData && sectionData.items && sectionData.items.length > 0) {
            setPublicReference(sectionData.items[0].publicReference);
        }
    }, [sectionId, sectionData]);

    useEffect(() => {
        setBeachId(+beachId);
    }, [beachId]);

    useEffect(() => {
        setCityId(+cityId);
    }, [cityId]);

    useEffect(() => {
        setPublicReference(mainPublicReference);
    }, [mainPublicReference]);

    const showMap = query?.showMap === 'true' || false;
    const showMapMarkers = query?.showMapMarkers === 'true' || false;
    const firstFromList = query?.random === 'true' || false;
    const { data } = useFilteredBeachChairDataByVendorReferencePublic(mainPublicReference);
    const sections = useFeaturesSections(
        cityId !== '0' ? +cityId : undefined,
        beachId !== '0' ? +beachId : undefined,
        mainPublicReference !== '' ? mainPublicReference : undefined,
    );

    const { setBookedId, bookedRef, setBookedRef, beachChairs, setBeachChairs, totalPrice, setTotalPrice } =
        useWidgetState();
    const methods = useForm();

    const startDate = React.useMemo(
        () => (methods.getValues('startDate') ? new Date(methods.getValues('startDate')) : new Date()),
        [methods.getValues('startDate')],
    );
    const endDate = React.useMemo(
        () => (methods.getValues('leaveDate') ? new Date(methods.getValues('leaveDate')) : new Date()),
        [methods.getValues('leaveDate')],
    );
    startDate.setHours(9);
    endDate.setHours(21);

    const rowIdWatch = useMemo(() => methods.watch('row'), [methods]);
    const sectionIdWatch = useMemo(() => methods.watch('sectionId'), [methods]);

    const getAvailableBeachChairsPublic = useCallback(async () => {
        const baseUrlFeatures = '/features';
        await client
            .get(
                `${baseUrlFeatures}/public/sections/${sectionIdWatch}/beach_chairs_availability?start=${startDate.toISOString()}&end=${endDate.toISOString()}&row=&free=true&timeZone=${GERMANY_TIME_ZONE}&publicReference=${mainPublicReference}`,
            )
            .then(response => {
                response.data[0].rowData = response.data[0].rowData.filter((beachChair:any) => {return (beachChair.timeEnum === 'boxBackgroundFree')});
                setErrorObject(undefined);
                setBeachChairsData(response.data);
            })
            .catch(error => {
                if (error.response) {
                    setErrorObject(error.response.data);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    }, [
        startDate.toISOString(),
        endDate.toISOString(),
        sectionIdWatch,
        rowIdWatch,
        GERMANY_TIME_ZONE,
        mainPublicReference,
    ]);

    useEffect(() => {
        if (sectionIdWatch && mainPublicReference) {
            getAvailableBeachChairsPublic();
        }
    }, [sectionIdWatch, rowIdWatch, getAvailableBeachChairsPublic, mainPublicReference]);

    const { showAlertDialog } = useAlertDialog();

    const {
        data: selectedDatesPrice,
        error: selectedDatesPriceError,
        isLoading: calculationLoading,
    } = useGetCalculation(
        methods.getValues('sectionId') || '',
        methods.getValues('row') || '',
        methods.getValues('model') || '',
        startDate.toISOString(),
        endDate.toISOString(),
        mainPublicReference,
    );

    const {
        data: priceData,
        refetch: getRatesFetch,
        isLoading: priceLoading,
    } = useGetRates(
        methods.getValues('sectionId') || '',
        methods.getValues('row') || '',
        methods.getValues('model') || '',
        startDate.toISOString() || '',
        endDate.toISOString() || '',
        mainPublicReference,
    );

    const {
        refetch: createBooking,
        error: createBookingError,
        isLoading: createBookingLoading,
    } = useCreateBookingPublic(mainPublicReference);

    const beachChairsDataFilteredByRow =
        beachChairsData && beachChairsData.find(beachChair => beachChair.id === methods.watch('row'));

    // TODO: when backend api fixed, then change its url
    const onSubmit = async () => {
        try {
            let bookingRef = '';
            if (bookedRef === '') {
                const response = await createBooking();
                setBookedId(response.data.id + '');
                bookingRef = response.data.bookingRef;
                setBookedRef(bookingRef);
            }

            const responseBeachChairs = await createBeachChairBookingPublic(
                bookedRef === '' ? bookingRef : bookedRef,
                mainPublicReference,
                {
                    beachChairId: methods.getValues('beachChairId'),
                    start: startDate.toISOString(),
                    end: endDate.toISOString(),
                    model: methods.getValues('model'),
                    rate: methods.getValues('rate') === 'none' ? undefined : methods.getValues('rate'),
                    // status: 'ACTIVE',
                    // start: new Date(formData.startDate).toISOString().split('T')[0] + 'T' + formData.startTime,
                    // end: new Date(formData.leaveDate).toISOString().split('T')[0] + 'T' + formData.endTime,
                },
            );
            methods.setValue('row', '');
            methods.setValue('model', '');
            // methods.setValue('beachChairId', '');
            setBeachChairs(responseBeachChairs.data.beachChairBookings || []);
            setTotalPrice(responseBeachChairs.data.totalPrice);
        } catch (error: any) {
            showAlertDialog({
                alertIconType: 'ERROR',
                title: 'ERROR',
                body:
                    error.response?.data?.message ||
                    error.response?.data?.errorType ||
                    createBookingError?.response?.data?.message ||
                    messageSet.unhandledMessage,
                primaryButton: { text: 'OK' },
            });
        }
    };

    const onRemove = async (beachChairBookingId: number) => {
        try {
            const responseBeachChairs = await deleteBeachChairBookingPublic(
                bookedRef,
                mainPublicReference,
                beachChairBookingId,
            );
            setBeachChairs(responseBeachChairs.data.beachChairBookings || []);
            setTotalPrice(responseBeachChairs.data.totalPrice);
        } catch (error: any) {
            showAlertDialog({
                alertIconType: 'ERROR',
                title: 'ERROR',
                body:
                    error.response?.data?.message ||
                    error.response?.data?.errorType ||
                    createBookingError?.response?.data?.message ||
                    messageSet.unhandledMessage,
                primaryButton: { text: 'OK' },
            });
        }
    };

    useEffect(() => {
        /**SECTION AUTO SELECT IF SECTIONS = 1 */
        if (sections.data && sections.data.items.map(item => ({ name: item.name, value: item.id })).length === 1) {
            const sectionValue = sections.data.items
                .map(item => ({ name: item.name, value: item.id }))
                .sort(alphabeticCompare)[0].value;
            methods.setValue('sectionId', sectionValue);
            setSectionId(sectionValue);
        }
    }, [sections.data]);

    useEffect(() => {
        setState(EMapState.SECTION);
        setRowsReady(false);
        setSectionId(methods.watch('sectionId'));
        if (methods.watch('sectionId') && methods.watch('startDate') && methods.watch('leaveDate')) {
            //getAvailableBeachChairsFetch();
            methods.setValue('row', '');
            methods.setValue('model', '');
            methods.setValue('beachChairId', '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [methods.watch('sectionId'), startDate, endDate, sections.data, data]);

    useEffect(() => {
        /**ROW AUTO SELECT IF ROWS = 1 */
        if (data && methods.watch('sectionId')) {
            if (data?.filter?.sections.find(section => section.id === +methods.watch('sectionId'))!.rows.length === 1) {
                const rowValue = data.filter?.sections
                    .find(section => section.id === +methods.watch('sectionId'))!
                    .rows.map(row => ({
                        name: row.value,
                        value: row.id,
                    }))
                    .sort(alphabeticCompare)[0].value;
                methods.setValue('row', rowValue);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [methods.watch('sectionId'), data, beachChairsData]);

    useEffect(() => {
        if (
            beachChairsDataFilteredByRow &&
            uniqBy(
                beachChairsDataFilteredByRow?.rowData.map(row => ({
                    value: row.model,
                    name: t('common:models.' + row.model),
                })),
                'value',
            ).length === 1
        ) {
            const modelValue = uniqBy(
                beachChairsDataFilteredByRow?.rowData.map(row => ({
                    value: row.model,
                    name: t('common:models.' + row.model),
                })),
                'value',
            ).sort(alphabeticCompare)[0].value;
            methods.setValue('model', `${modelValue}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [beachChairsDataFilteredByRow, methods.watch('row')]);

    useEffect(() => {
        if (
            beachChairsDataFilteredByRow &&
            methods.watch('sectionId') &&
            methods.watch('startDate') &&
            methods.watch('leaveDate') &&
            methods.watch('row') &&
            methods.watch('model')
        ) {
            if (firstFromList) {
                methods.setValue('beachChairId', beachChairsDataFilteredByRow?.rowData[0]?.id || '');
            } else {
                methods.setValue('beachChairId', '');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [methods.watch('model'), firstFromList, beachChairsDataFilteredByRow]);

    useEffect(() => {
        if (
            beachChairsDataFilteredByRow &&
            beachChairsDataFilteredByRow.rowData
                .filter(rowData => rowData.model === methods.watch('model'))
                .map(row => ({
                    value: row.id,
                    name: `${row.publicNumber} ${
                        row.attributes.color ? ' / ' + t('common:colors.' + row.attributes.color) : ''
                    }`,
                })).length === 1
        ) {
            const beachChairValue = beachChairsDataFilteredByRow.rowData
                .filter(rowData => rowData.model === methods.watch('model'))
                .map(row => ({
                    value: row.id,
                    name: `${row.publicNumber} ${
                        row.attributes.color ? ' / ' + t('common:colors.' + row.attributes.color) : ''
                    }`,
                }))
                .sort(alphabeticCompare)[0].value;
            methods.setValue('beachChairId', `${beachChairValue}`);
        }
    }, [methods.watch('model')]);

    useEffect(() => {
        if (
            methods.watch('sectionId') &&
            methods.watch('startDate') &&
            methods.watch('leaveDate') &&
            methods.watch('row') &&
            methods.watch('model') &&
            methods.watch('beachChairId')
        ) {
            getRatesFetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [methods.watch('beachChairId')]);

    return (
        <>
            <FormProvider {...methods}>
                <form autoComplete="off">
                    <SelectTicketPanelBlock isShown={isShown}>
                        {showMap && (
                            <div className="row-container">
                                <Maps showMapMarkers={showMapMarkers} />
                            </div>
                        )}
                        <div className="row-container">
                            <div>
                                <FormDropdown
                                    name="sectionId"
                                    label={t('widget:section')}
                                    placeholder={t(`common:pleaseSelect`)}
                                    options={
                                        sections.data
                                            ? sections.data.items
                                                  .map(item => ({ name: item.name, value: item.id }))
                                                  .sort(alphabeticCompare)
                                            : []
                                    }
                                    required
                                    rules={{ required: t('common:requiredNotification') as string }}
                                    disabled={
                                        !Boolean(
                                            sections.data && sections.data.items ? sections.data.items.length : false,
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <FormDatePicker
                            disabled={methods.watch('sectionId') === undefined}
                            methods={methods}
                            labelStart={t('widget:pickup')}
                            labelEnd={t('widget:dropoff')}
                            required
                        />
                        {methods.watch('sectionId') && methods.watch('startDate') && methods.watch('leaveDate') && (
                            <>
                                <div className="row-grid">
                                    <FormDropdown
                                        name="row"
                                        label={t('widget:row')}
                                        options={
                                            Array.isArray(
                                                data?.filter?.sections?.find(
                                                    section => section.id === methods.watch('sectionId'),
                                                )?.rows,
                                            )
                                                ? data!.filter?.sections
                                                      .find(section => section.id === +methods.watch('sectionId'))!
                                                      .rows.map(row => ({
                                                          name: row.value,
                                                          value: row.id,
                                                      }))
                                                      .sort(alphabeticCompare)
                                                : []
                                        }
                                        required
                                        rules={{ required: t('common:requiredNotification') as string }}
                                        onClick={() => {
                                            methods.setValue('model', '');
                                            methods.setValue('beachChairId', '');
                                        }}
                                        disabled={
                                            !Boolean(
                                                Array.isArray(
                                                    data?.filter?.sections?.find(
                                                        section => section.id === +methods.watch('sectionId'),
                                                    )?.rows,
                                                )
                                                    ? data!.filter?.sections
                                                          .find(section => section.id === +methods.watch('sectionId'))!
                                                          .rows.map(row => ({
                                                              name: row.value,
                                                              value: row.value,
                                                          })).length
                                                    : false,
                                            )
                                        }
                                    />
                                    <FormDropdown
                                        name="model"
                                        label={t('widget:model')}
                                        options={
                                            Array.isArray(beachChairsData) &&
                                            Array.isArray(beachChairsDataFilteredByRow?.rowData)
                                                ? uniqBy(
                                                      beachChairsDataFilteredByRow?.rowData.map(row => ({
                                                          value: row.model,
                                                          name: t('common:models.' + row.model),
                                                      })),
                                                      'value',
                                                  ).sort(alphabeticCompare)
                                                : []
                                        }
                                        disabled={
                                            !Boolean(
                                                Array.isArray(beachChairsData) &&
                                                    Array.isArray(beachChairsDataFilteredByRow?.rowData)
                                                    ? uniqBy(
                                                          beachChairsDataFilteredByRow?.rowData.map(row => ({
                                                              value: row.model,
                                                              name: t('common:models.' + row.model),
                                                          })),
                                                          'value',
                                                      ).length
                                                    : false,
                                            )
                                        }
                                        required
                                        rules={{ required: t('common:requiredNotification') as string }}
                                    />
                                    <div hidden={firstFromList}>
                                        <FormDropdown
                                            name="beachChairId"
                                            label={t('beachChairNumber')}
                                            options={
                                                Array.isArray(beachChairsData) &&
                                                Array.isArray(beachChairsDataFilteredByRow?.rowData)
                                                    ? beachChairsDataFilteredByRow
                                                        ? beachChairsDataFilteredByRow?.rowData
                                                              .filter(
                                                                  rowData => rowData.model === methods.watch('model'),
                                                              )
                                                              .map(row => ({
                                                                  value: row.id,
                                                                  name: `${row.publicNumber} ${
                                                                      row.attributes.color
                                                                          ? ' / ' +
                                                                            t('common:colors.' + row.attributes.color)
                                                                          : ''
                                                                  }`,
                                                              }))
                                                              .sort(alphabeticCompare)
                                                        : []
                                                    : []
                                            }
                                            disabled={
                                                !Boolean(
                                                    Array.isArray(beachChairsData) &&
                                                        Array.isArray(beachChairsDataFilteredByRow?.rowData)
                                                        ? beachChairsDataFilteredByRow
                                                            ? beachChairsDataFilteredByRow?.rowData
                                                                  .filter(
                                                                      rowData =>
                                                                          rowData.model === methods.watch('model'),
                                                                  )
                                                                  .map(row => ({
                                                                      value: row.id,
                                                                      name: `${row.publicNumber} ${
                                                                          row.attributes.color
                                                                              ? ' / ' +
                                                                                t(
                                                                                    'common:colors.' +
                                                                                        row.attributes.color,
                                                                                )
                                                                              : ''
                                                                      }`,
                                                                  })).length
                                                            : false
                                                        : false,
                                                )
                                            }
                                            required
                                            rules={{ required: t('common:requiredNotification') as string }}
                                        />
                                    </div>
                                </div>
                                {errorObject && methods.watch('row') !== `` && (
                                    <div className="errorMessage">
                                        {t(`common:${errorObject.errorType}`, {
                                            payload: errorObject.payload,
                                        })}
                                    </div>
                                )}
                                {methods.watch('beachChairId') !== '' &&
                                    dateDiffInDays(
                                        new Date(methods.getValues('startDate')),
                                        new Date(methods.getValues('leaveDate')),
                                    ) > 0 &&
                                    calculationLoading === false &&
                                    selectedDatesPriceError &&
                                    (selectedDatesPriceError as any).response.data.errorType &&
                                    (selectedDatesPriceError as any).response.data.payload && (
                                        <div className="errorMessage">{t(`common:PRICE_CALCULATION_ERROR`)}</div>
                                    )}
                                {methods.watch('beachChairId') !== '' &&
                                    dateDiffInDays(
                                        new Date(methods.getValues('startDate')),
                                        new Date(methods.getValues('leaveDate')),
                                    ) === 0 &&
                                    priceLoading === false &&
                                    Object.keys(priceData || {}).length === 0 && (
                                        <div className="errorMessage">{t(`common:PRICE_DEFINITION_ERROR`)}</div>
                                    )}
                                {methods.watch('beachChairId') !== '' &&
                                    methods.watch('row') &&
                                    methods.watch('startDate') &&
                                    methods.watch('leaveDate') && (
                                        <div className="tickets-grid">
                                            <TicketButtonGroup
                                                name="rate"
                                                label={t('rates')}
                                                required
                                                rules={{ required: t('common:requiredNotification') as string }}
                                                priceData={priceData}
                                                selectedDatesPrice={
                                                    (selectedDatesPrice as unknown as { price: number })
                                                        ? (selectedDatesPrice as unknown as { price: number }).price
                                                        : 0
                                                }
                                                startDate={methods.getValues('startDate')}
                                                endDate={methods.getValues('leaveDate')}
                                            />
                                            <Button
                                                variant="text"
                                                disableRipple={true}
                                                className="add-icon"
                                                sx={{ mt: 0 }}
                                                onClick={() => onSubmit()}
                                            >
                                                {t(`common:additionalChair`)}{' '}
                                                <AddCircleOutlineIcon style={{ width: '20px' }} />
                                            </Button>
                                        </div>
                                    )}
                                {beachChairs.length > 0 && (
                                    <div>
                                        {beachChairs.map(beachChair => {
                                            return (
                                                <div className="added-beach-chair" key={`bcid-${beachChair.id}`}>
                                                    <div>{beachChair.publicNumber}</div>
                                                    <div>
                                                        {beachChair.model
                                                            ? t('common:models.' + beachChair.model)
                                                            : null}
                                                    </div>
                                                    <div>{`${dateToProperFormat(
                                                        beachChair.start,
                                                    )} - ${dateToProperFormat(beachChair.end)}`}</div>
                                                    <div>{currencyFormatter(beachChair.price + '') + ' €'}</div>
                                                    <IconButton
                                                        className="remove-icon"
                                                        disableRipple={true}
                                                        onClick={() => onRemove(beachChair.id)}
                                                    >
                                                        <DeleteForeverIcon style={{ width: '20px', height: '20px' }} />
                                                    </IconButton>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                                {totalPrice > 0 && methods.watch('startDate') && methods.watch('leaveDate') && (
                                    <div className="next-btn-container">
                                        <div className="total-price">
                                            {t('common:TOTAL_PRICE')}:
                                            {beachChairs.length > 0 && Object.keys(priceData || {}).length !== 0
                                                ? Price(totalPrice)
                                                : Price(
                                                      (selectedDatesPrice as unknown as { price: number })?.price || 0,
                                                  )}
                                        </div>
                                        <LoadingButton
                                            color="primary"
                                            variant="contained"
                                            loading={createBookingLoading}
                                            onClick={() => {
                                                shownTrigger();
                                            }}
                                        >
                                            {t('next')}
                                        </LoadingButton>
                                    </div>
                                )}
                            </>
                        )}
                        <Box style={{ maxWidth: '550px', margin: 'auto', marginTop: '5px' }}>
                            <Terms />
                        </Box>
                    </SelectTicketPanelBlock>
                </form>
            </FormProvider>
        </>
    );
};

export default SelectTicketPanel;
