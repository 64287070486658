import { Marker } from '@react-google-maps/api';
import { Polygon } from '@react-google-maps/api';
import logo from './../../../assets/SBLMarker.png';
import { Fragment } from 'react';
import React from 'react';
import * as turf from '@turf/turf';
import { useGetSections } from 'src/api/beachChairsAPI';
import { useFormContext } from 'react-hook-form';
import { IBeachChairAvailableRow } from 'src/models/beachChairs/IBeachChairAvailableRow';
import { MyMapContext, MyMapContextType } from './mapContext';

export const getCentroid = (geoJson: object) => {
    const polygon = turf.polygon((geoJson as IGeoPolygon).coordinates);
    return turf.centroid(polygon);
};

export interface IMapStateProps {
    setMapBounds: React.Dispatch<React.SetStateAction<google.maps.LatLngBounds | undefined>>;
    beachChairsData?: {
        geoJson: object;
        name: string;
        id: number;
        rowData: IBeachChairAvailableRow[];
    }[];
    showMapMarkers: boolean;
}

export interface IGeo {
    type: string;
}

export interface IGeoPoint {
    type: string;
    coordinates: number[];
}

export interface IGeoPolygon {
    type: string;
    coordinates: number[][][];
}

export const geoJsonGuard = (geoJson: object): boolean => {
    if (!geoJson.hasOwnProperty('type') && !geoJson.hasOwnProperty('coordinates')) return false;
    const geo = geoJson as IGeo;
    if (!(geo.type.includes('Point') || geo.type.includes('Polygon'))) return false;
    if (geo.type.includes('Point')) {
        const geoPoint = geoJson as IGeoPoint;
        if (geoPoint.coordinates.length !== 2) return false;
        geoPoint.coordinates.forEach(coord => {
            if (isNaN(coord)) return false;
        });
    }
    if (geo.type.includes('Polygon')) {
        const geoPoint = geoJson as IGeoPolygon;
        geoPoint.coordinates[0].forEach(coord => {
            if (coord.length !== 2) return false;
            coord.forEach(coord => {
                if (isNaN(coord)) return false;
            });
        });
    }
    return true;
};

const BeachMapState = ({ setMapBounds, showMapMarkers }: IMapStateProps) => {
    const { data: sections } = useGetSections();
    const { setValue, watch } = useFormContext();
    const { publicReference, beachId, cityId, setPublicReference } = React.useContext(MyMapContext) as MyMapContextType;
    const sectionId = watch('sectionId');

    const beachSections = React.useMemo(
        () =>
            publicReference !== ''
                ? sections?.items
                      .filter(section => geoJsonGuard(section.geoJson))
                      .filter(section => section.publicReference === publicReference)
                : cityId !== 0
                ? sections?.items
                      .filter(section => geoJsonGuard(section.geoJson))
                      .filter(section => section.cityId === cityId)
                : sections?.items
                      .filter(section => geoJsonGuard(section.geoJson))
                      .filter(section => section.beachId === beachId),
        [sections, publicReference, beachId, cityId],
    );

    React.useEffect(() => {
        if (beachSections) {
            const bounds = new window.google.maps.LatLngBounds();
            if (beachSections.length > 1) {
                beachSections.forEach(section => {
                    let geoJson = section.geoJson;
                    let centroid = getCentroid(geoJson);
                    bounds.extend({
                        lat: centroid.geometry.coordinates[1],
                        lng: centroid.geometry.coordinates[0],
                    });
                });
            } else {
                beachSections.forEach(section => {
                    const path =
                        Object.keys(section.geoJson).length !== 0
                            ? (section.geoJson as IGeoPolygon).coordinates[0].map(coord => {
                                  return { lat: coord[1], lng: coord[0] };
                              })
                            : [{ lat: 0, lng: 0 }];
                    path.forEach(point => {
                        bounds.extend({
                            lat: point.lat,
                            lng: point.lng,
                        });
                    });
                });
            }
            setMapBounds(bounds);
        }
    }, [beachSections, setMapBounds]);

    React.useEffect(() => {
        if (beachSections && sectionId) {
            const bounds = new window.google.maps.LatLngBounds();
            beachSections
                .filter(section => section.id === sectionId)
                .forEach(section => {
                    const path =
                        Object.keys(section.geoJson).length !== 0
                            ? (section.geoJson as IGeoPolygon).coordinates[0].map(coord => {
                                  return { lat: coord[1], lng: coord[0] };
                              })
                            : [{ lat: 0, lng: 0 }];
                    path.forEach(point => {
                        bounds.extend({
                            lat: point.lat,
                            lng: point.lng,
                        });
                    });
                });
            setMapBounds(bounds);
        }
    }, [beachSections, sectionId, setMapBounds]);

    return (
        <>
            {beachSections &&
                beachSections.map(section => {
                    let geoJson = section.geoJson;
                    let centroid = getCentroid(geoJson);
                    if (geoJson && Object.keys(geoJson).length === 0 && geoJson.constructor === Object) {
                        return <></>;
                    } else {
                        const path = (geoJson as IGeoPolygon).coordinates[0].map(coord => {
                            return { lat: coord[1], lng: coord[0] };
                        });
                        return (
                            <Fragment key={`fragment-${section.id}-${section.beachId}`}>
                                <Polygon
                                    path={path}
                                    key={`polygon-${section.id}-${section.beachId}`}
                                    options={{
                                        strokeColor: '#1A4090',
                                        fillColor: '#ED6B16',
                                        strokeOpacity: 0.78,
                                        strokeWeight: 1,
                                        fillOpacity: 0.5,
                                    }}
                                    onClick={() => {
                                        setValue('sectionId', section.id);
                                        setPublicReference(section.publicReference);
                                    }}
                                />
                                {showMapMarkers ? (
                                    <Marker
                                        onClick={() => {
                                            setValue('sectionId', section.id);
                                            setPublicReference(section.publicReference);
                                        }}
                                        key={`marker-${section.id}-${section.beachId}`}
                                        position={{
                                            lat: centroid.geometry.coordinates[1],
                                            lng: centroid.geometry.coordinates[0],
                                        }}
                                        label={{
                                            text: section.name,
                                            color: 'white',
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                            className: 'MapLabel',
                                        }}
                                        icon={{
                                            url: logo,
                                            scaledSize: new google.maps.Size(50, 50),
                                        }}
                                    />
                                ) : null}
                            </Fragment>
                        );
                    }
                })}
            {beachSections &&
                beachSections
                    .filter(section => section.id === sectionId)
                    .map(section => {
                        let geoJson = section.geoJson;
                        if (geoJson && Object.keys(geoJson).length === 0 && geoJson.constructor === Object) {
                            return <></>;
                        } else {
                            const path = (geoJson as IGeoPolygon).coordinates[0].map(coord => {
                                return { lat: coord[1], lng: coord[0] };
                            });
                            return (
                                <Fragment key={`fragment-${section.id}-${section.beachId}`}>
                                    <Polygon
                                        path={path}
                                        key={`polygon-${section.id}-${section.beachId}`}
                                        options={{
                                            strokeColor: '#1A4090',
                                            fillColor: '#ED6B16',
                                            strokeOpacity: 0.78,
                                            strokeWeight: 1,
                                            fillOpacity: 0.5,
                                        }}
                                    />
                                </Fragment>
                            );
                        }
                    })}
        </>
    );
};

export default React.memo(BeachMapState);
