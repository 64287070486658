import { format, utcToZonedTime } from 'date-fns-tz';

export function formatInUtc(date: Date, fmt: string): string {
  return format(utcToZonedTime(date, 'UTC'), fmt, { timeZone: 'UTC' });
}

const timezone = '+0200';

export const dateToProperFormat = (
  date: string,
): string => {
  return format(utcToZonedTime(new Date(date), timezone), `dd.MM.yyyy - HH:mm`);
};

export const dateToProperTimeFormat = (date: string): string => {
  return format(utcToZonedTime(new Date(date), timezone), `HH:mm`);
};

export const dateToProperDateFormat = (date: string): string => {
  return format(utcToZonedTime(new Date(date), timezone), `yyyy-MM-dd`);
};

export const dateToProperDateCartFormat = (date: string): string => {
  return format(utcToZonedTime(new Date(date), timezone), `dd.MM.yyyy`);
};