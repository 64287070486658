import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';
import withErrorBoundary from 'src/hook/withErrorBoundary';
import { IRouteElement } from './models/IRouteElement';
import BookBeachChairPage from './pages/BookBeachChairPage';
import BookBeachChairResultPage from './pages/BookBeachChairResultPage';
import AppLayout from './components/views/layout/AppLayout';
import { AlertDialogContextProvider, DialogContextProvider } from 'src/hook';
import { WidgetContextProvider } from './components/views/widget/WidgetContext';
import { MapContextProvider } from './components/views/widget/mapContext';

function App() {
    const routeElements: IRouteElement[] = useMemo<IRouteElement[]>(
        () => [
            {
                path: '/beach-chairs/:id',
                component: BookBeachChairPage,
                keyId: 'book-beach-chair',
            },
            {
                path: '/beach-chairs/result/:params',
                component: BookBeachChairResultPage,
                keyId: 'book-beach-chair-result',
            },
        ],
        [],
    );

    return (
        <MapContextProvider>
            <WidgetContextProvider>
                <DialogContextProvider>
                    <AlertDialogContextProvider>
                        <AppLayout>
                            <Helmet>
                                <title>Strandbutler</title>
                            </Helmet>
                            <Switch>
                                {routeElements.map((route: IRouteElement) => {
                                    const { path, component, keyId } = route;
                                    return <Route exact path={path} component={component} key={keyId} />;
                                })}
                            </Switch>
                        </AppLayout>
                    </AlertDialogContextProvider>
                </DialogContextProvider>
            </WidgetContextProvider>
        </MapContextProvider>
    );
}

export default withErrorBoundary(App);
