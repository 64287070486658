import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useState } from 'react';

interface ISnackBarContext {
    isSnackBarOpen: boolean;
    setIsSnackBarOpen: Dispatch<SetStateAction<boolean>>;
    snackBarMessage: string;
    setSnackBarMessage: Dispatch<SetStateAction<string>>;
    onSnackbarOpenHandler: (message: string) => void;
    onSnackBarCloseHandler: () => void;
}

const SnackBarContext = createContext<ISnackBarContext | undefined>(undefined);

export function SnackBarContextProvider({ children }: { children: ReactNode }) {
    const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');

    const onSnackbarOpenHandler = useCallback(
        message => {
            setSnackBarMessage(message);
            setIsSnackBarOpen(true);
        },
        [setIsSnackBarOpen, setSnackBarMessage],
    );

    const onSnackBarCloseHandler = useCallback(() => {
        setIsSnackBarOpen(false);
    }, [setIsSnackBarOpen]);

    return (
        <SnackBarContext.Provider
            value={{
                isSnackBarOpen,
                setIsSnackBarOpen,
                onSnackBarCloseHandler,
                snackBarMessage,
                setSnackBarMessage,
                onSnackbarOpenHandler,
            }}
        >
            {children}
        </SnackBarContext.Provider>
    );
}

export function useSnackBarState(): any {
    const state = useContext(SnackBarContext);
    if (!state) throw new Error('CountryProvider not found');
    return state;
}
