import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

export default function useFormUtil(name: string) {
    const {
        formState: { errors },
        getValues,
    } = useFormContext();

    const fieldError = useMemo(() => {
        const splitName = name?.split(/[,[\].]+?/).filter(Boolean);
        return splitName.reduce((prevError, curName) => {
            return prevError?.[curName];
        }, errors);
    }, [errors, name]);

    const value = useMemo(() => {
        const values = getValues();
        const splitName = name?.split(/[,[\].]+?/).filter(Boolean);
        return splitName.reduce((prevValue, curName) => {
            return prevValue?.[curName];
        }, values);
    }, [getValues, name]);

    return { fieldError, value };
}

export const emailPattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,99}$/;
