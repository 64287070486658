import { createMuiTheme } from '@material-ui/core/styles';

const customTheme = createMuiTheme({
    palette: {
        action: {
            disabled: '#222222',
            disabledBackground: '#e5e5e5',
        },
        primary: {
            main: '#233f91',
          },
        secondary: {
        main: '#ed6b17',
        },
    },
});

export default customTheme;
