import React from 'react';
import Modal from 'src/components/common/modal/Modal';
import { useModalState } from 'src/components/common/modal/ModalContext';
import SnackBar from 'src/components/common/snackbar/SnackBar';
import { useSnackBarState } from 'src/components/common/snackbar/SnackBarContext';

const AppTool: React.FC = () => {
    const { isModalOpen, onModalCloseHandler, modalMessage, leftButtonText, rightButtonText, onModalExecuteHandler } =
        useModalState();
    const { isSnackBarOpen, onSnackBarCloseHandler, snackBarMessage } = useSnackBarState();

    return (
        <>
            <Modal
                open={isModalOpen}
                title={modalMessage}
                leftButtonText={leftButtonText}
                leftButtonOnClick={onModalCloseHandler}
                rightButtonText={rightButtonText}
                rightButtonOnClick={onModalExecuteHandler}
            />
            <SnackBar message={snackBarMessage} isOpen={isSnackBarOpen} handle={onSnackBarCloseHandler} />
        </>
    );
};

export default AppTool;
