import React from 'react';
import AppTool from './AppTool';
import styled from 'styled-components';

const AppLayoutBlock = styled.div`
    height: 100%;

    padding: 2vw;
`;

const AppLayout: React.FC = ({ children }) => {
    return (
        <AppLayoutBlock>
            <AppTool />
            {children}
        </AppLayoutBlock>
    );
};

export default AppLayout;
