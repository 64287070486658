import { makeStyles, Snackbar as MaterialUISnackbar } from '@material-ui/core';
import React from 'react';

interface ISnackBarProps {
    isOpen: boolean;
    message: string;
    autoHideDuration?: number;
    handle: () => void;
}

const useStyles = makeStyles(() => ({
    root: {
        '& > .MuiSnackbarContent-root': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
}));

const SnackBar: React.FC<ISnackBarProps> = ({ isOpen, message, autoHideDuration = 2000, handle }) => {
    const classes = useStyles();

    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        handle();
    };
    return (
        <MaterialUISnackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            classes={{
                root: classes.root,
            }}
            onClose={handleClose}
            open={isOpen}
            message={message}
            autoHideDuration={autoHideDuration}
            className={classes.root}
        />
    );
};

export default React.memo(SnackBar);
