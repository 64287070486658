import { createContext, Dispatch, ReactNode, useContext, useEffect, useReducer } from 'react';
import { AlertDialog } from 'src/components/dialog/AlertDialog';

export interface AlertDialogState {
    open: boolean;
    alertIconType?: 'ERROR' | 'INFO';
    title?: ReactNode;
    body?: ReactNode;
    onClose?: () => void;
    primaryButton?: {
        text: string;
        callback?: () => boolean | void | Promise<boolean | void>;
    };
    secondaryButton?: {
        text: string;
        callback?: () => boolean | void | Promise<boolean | void>;
    };
    /**
     * Determine the max-width of the dialog.
     * The dialog width grows with the size of the screen.
     * Set to `false` to disable `maxWidth`.
     */
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

type Action =
    | { type: 'INIT'; options?: Omit<AlertDialogState, 'open'> }
    | { type: 'SHOW'; options?: Omit<AlertDialogState, 'open'> }
    | { type: 'CLOSE' };

type DialogDispatch = Dispatch<Action>;
const DialogDispatchContext = createContext<DialogDispatch | undefined>(undefined);

function alertDialogReducer(state: AlertDialogState, action: Action): AlertDialogState {
    switch (action.type) {
        case 'INIT':
            return { open: false, ...action.options };
        case 'SHOW':
            return { open: true, ...action.options };
        case 'CLOSE':
            return { ...state, open: false };
        default:
            throw new Error('Unhandled action');
    }
}

export function AlertDialogContextProvider({ children }: { children: ReactNode }) {
    const [state, dispatch] = useReducer(alertDialogReducer, { open: false, title: '', body: '' });

    const handleClose = () => {
        state.onClose && state.onClose();
        dispatch({ type: 'CLOSE' });
    };

    return (
        <DialogDispatchContext.Provider value={dispatch}>
            {children}
            <AlertDialog {...state} onClose={handleClose} />
        </DialogDispatchContext.Provider>
    );
}

function useAlertDialogDispatch() {
    const dispatch = useContext(DialogDispatchContext);
    if (!dispatch) throw new Error('alertDialogProvider not found');
    return dispatch;
}

export function useAlertDialog(props?: Omit<AlertDialogState, 'open'>) {
    const dispatch = useAlertDialogDispatch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => dispatch({ type: 'INIT', options: props }), [dispatch]);

    return {
        showAlertDialog: (props?: Omit<AlertDialogState, 'open'>) => dispatch({ type: 'SHOW', options: props }),
        closeDialog: () => dispatch({ type: 'CLOSE' }),
        showSimpleDialog: (alertIconType: 'ERROR' | 'INFO', title: ReactNode, body: ReactNode) =>
            dispatch({
                type: 'SHOW',
                options: { alertIconType, title, body, primaryButton: { text: 'OK' } },
            }),
    };
}
